// Sirve para aplanar un objeto con todos sus atributos al mismo nivel. De esta forma, si tiene objetos embebidos, 
// pasan todos los atributos de estos a encontrarse como atributos del objeto principal

export const flattenObject = (data, result = {}) => {
    Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            // Si el valor es un objeto, hacer llamada recursiva
            flattenObject(value, result);
        } else {
            // Si no es un objeto, agregarlo al resultado
            result[key] = value;
        }
    });
    
    return result;
};