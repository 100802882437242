import React, { useEffect, useState } from 'react'
import styles from './travelmodal.module.css'
import Modal from '@mui/material/Modal';
import { api, ensureAuthorizationHeader } from '../../../../Config/api';
import { useParams } from 'react-router-dom';
import LoadingRing from '../../../../Static/LoadingRingV2.svg'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTimeToReadableString } from '../../../../Utils/DurationToDateTime'

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export const TravelModal = ({ open, handleClose }) => {

    const { username } = useParams();

    const [travelData, setTravelData] = useState();

    const [loadingProfile, setLoadingData] = useState(true);

    useEffect(() => {
        const fetchTravelData = async () => {
            setLoadingData(true);
            try {
                // Asegúrate de que el JWT esté configurado en la petición a la api
                ensureAuthorizationHeader();

                const response = await api.get(`/api/v1/travel/${username}`);
                setTravelData(response.data); // Asume que la respuesta tiene una propiedad 'data' que contiene el perfil del estado

            } catch (error) {
                console.error('Error fetching travel data:', error);
            } finally {
                setLoadingData(false);
            }
        };

        fetchTravelData();
    }, []);

    const travel = async () => {
        setLoadingData(true);
        try {
            ensureAuthorizationHeader();

            const response = await api.post(`/api/v1/travel/${username}`);
            setTravelData(response.data);

        } catch (error) {
            console.log('Error travelong: ', error);
        } finally {
            setLoadingData(false);
        }

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } } }}
        >

            <div className={styles.modalContainer}>

                <div className={styles.header}>
                    <div className={styles.headerLeft}>
                        <p className={styles.headerText}><strong>Travel info</strong></p>
                    </div>
                    <IconButton onClick={handleClose} aria-label='delete'>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </div>

                {loadingProfile
                    ?
                    <div className={styles.loadingContainer}>
                        <img src={LoadingRing} />
                    </div>
                    :
                    <div className={styles.travelDataContainer}>

                        <div className={styles.cities}>

                            <div className={styles.cityAndCountry}>

                                <div className={styles.cityOrCountry}>
                                    <img src={travelData?.cityOfOriginShieldUrl} />
                                    <p><strong>{travelData?.cityOfOriginName}</strong></p>
                                </div>
                                <div className={styles.cityOrCountry}>
                                    <img src={travelData?.countryOfOriginShieldUrl} />
                                    <p>{travelData?.countryOfOriginName}</p>
                                </div>

                            </div>
                            <KeyboardDoubleArrowRightIcon className={styles.arrowIcon} />
                            <div className={styles.cityAndCountry}>

                                <div className={styles.cityOrCountry}>
                                    <img src={travelData?.destinationCountryShieldUrl} />
                                    <p>{travelData?.destinationCountryName}</p>
                                </div>
                                <div className={styles.cityOrCountry}>
                                    <img src={travelData?.destinationCityShieldUrl} />
                                    <p><strong>{travelData?.destinationCityName}</strong></p>
                                </div>

                            </div>

                        </div>

                        {travelData?.traveling
                            ?
                            <>
                                <p>Currently traveling!</p>

                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Arrival date time:</p>
                                    <p>{formatDateTimeToReadableString(travelData?.arrivalDateAndTime)}</p>
                                </div>

                                <div className={styles.levelAndExperienceBar}>

                                    <p><strong>{travelData?.percentageOfDistanceTraveled}%</strong></p>
                                    <div className={styles.experienceBarContainer}>
                                        <div className={styles.experienceBar} style={{ width: `${travelData?.percentageOfDistanceTraveled}%` }}></div>
                                    </div>

                                </div>
                            </>
                            :
                            <>
                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Distance:</p>
                                    <p>{travelData?.kilometersAway} km.</p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Arrival date time:</p>
                                    <p>{formatDateTimeToReadableString(travelData?.arrivalDateAndTime)}</p>
                                </div>

                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Exit ban:</p>
                                    <p className={travelData?.exitBan ? styles.greenText : styles.redText}>{travelData?.exitBan ? 'YES' : 'NO'}</p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Visa requirement:</p>
                                    <p className={travelData?.visaRequirement ? styles.greenText : styles.redText}>{travelData?.visaRequirement ? 'YES' : 'NO'}</p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Has visa:</p>
                                    <p className={travelData?.hasVisa ? styles.greenText : styles.redText}>{travelData?.hasVisa ? 'YES' : 'NO'}</p>
                                </div>
                                <div className={styles.infoItem}>
                                    <p className={styles.title}>Can travel:</p>
                                    <p className={travelData?.canTravel ? styles.greenText : styles.redText}><strong>{travelData?.canTravel ? 'YES' : 'NO'}</strong></p>
                                </div>



                                <button onClick={()=>travel()}>Travel</button>
                            </>
                        }




                    </div>

                }



            </div>

        </Modal>
    )
}
