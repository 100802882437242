import { FOLLOW_UNFOLLOW_FAILURE, FOLLOW_UNFOLLOW_SUCCESS, GET_OWN_PLAYER_PROFILE_FAILURE, GET_OWN_PLAYER_PROFILE_SUCCESS, GET_PLAYER_PROFILE_FAILURE, GET_PLAYER_PROFILE_SUCCESS, LEVEL_UP_SKILL_SUCCESS, UPDATE_PROFILE_FAILURE, UPDATE_PROFILE_SUCCESS, WORK_DISCIPLINE_FAILURE, WORK_DISCIPLINE_SUCCESS } from "./ActionType";

const initialState = {
    loading: false,
    error: null,

    playerProfile: null,
    following: false,

    ownPlayerProfile: null,
}

export const playerProfileReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PLAYER_PROFILE_SUCCESS:
            console.log(action.payload.nationalityPreviews)
            return {
                ...state,
                loading: false,
                error: null,
                playerProfile: {
                    ...action.payload.playerProfileView,
                    nationalityPreviews: action.payload.nationalityPreviews
                },
                following: action.payload.following
            };

        case GET_OWN_PLAYER_PROFILE_SUCCESS:
            console.log(action.payload.nationalityPreviews)
            return {
                ...state,
                loading: false,
                error: null,
                ownPlayerProfile: {
                    ...action.payload.ownPlayerProfileView,
                    nationalityPreviews: action.payload.nationalityPreviews
                }
            };

        case FOLLOW_UNFOLLOW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                following: !state.following,
                playerProfile : {
                    ...state.playerProfile,
                    followersNumber : action.payload
                }

            };

        case WORK_DISCIPLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ownPlayerProfile: {
                    ...state.ownPlayerProfile,
                    levelExperienceStreak: action.payload
                }
            };

        case LEVEL_UP_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ownPlayerProfile: {
                    ...state.ownPlayerProfile,
                    skills: {
                        ...state.ownPlayerProfile?.skills,
                        momentOfImprovement: action.payload.momentOfImprovement,
                        improvingSkill: action.payload.improvingSkill
                    }

                }
            }

        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ownPlayerProfile: {
                    ...state.ownPlayerProfile,
                    targetUser: {
                        ...state.ownPlayerProfile.targetUser,
                        name: action.payload.name,
                        about: action.payload.about,
                        profileImageUrl: action.payload.profileImageUrl,
                        backgroundImageUrl: action.payload.backgroundImageUrl
                    },
                    socialIdeology: action.payload.socialIdeology,
                    socialIdeologyAttribute: action.payload.socialIdeologyAttribute,
                    economicIdeology: action.payload.economicIdeology,
                    powerStructureIdeology: action.payload.powerStructureIdeology,
                    formOfGovernmentIdeology: action.payload.formOfGovernmentIdeology
                }
            }


        case GET_PLAYER_PROFILE_FAILURE:
        case GET_OWN_PLAYER_PROFILE_FAILURE:
        case FOLLOW_UNFOLLOW_FAILURE:
        case WORK_DISCIPLINE_FAILURE:
        case UPDATE_PROFILE_FAILURE:
            return { ...state, loading: false, error: action.payload }


        default:
            return state;
    }
}