export const calculateInitialPosition = () => {

    let width = window.innerWidth;
    let height = window.innerHeight;

    let initialPosition = {
        x: 0,
        y: 0
    }


    if (width > 1800) {
        initialPosition.x = -3300;
    }
    else if (width > 1600 && width <=1800) {
        initialPosition.x = -2700;
    }
    else if (width > 1400 && width <= 1600) {
        initialPosition.x = -2500;
    }
    else if (width > 1200 <= 1400) {
        initialPosition.x = -1700;
    } else if (width > 1000 <= 1200) {
        initialPosition.x = -1000;
    }

    if (height > 600) {
        initialPosition.y = -2450;
    }

    return initialPosition;

}