import React, { useEffect, useRef, useState } from 'react'
import styles from './b2bpage.module.css'
import SearchIcon from '@mui/icons-material/Search';

import LanguageIcon from '@mui/icons-material/Language';

import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import AlimamaLogo from './AlimamaLogo.png'
import Donaldo from './DonaldoTrompeta.jpg'
import { Avatar } from '@mui/material';
import { DropdownMenu } from '../../Utils/Dropdown/DropdownMenu';
import { ResponsiveMenuButton } from '../../Utils/ResponsiveMenuButton/ResponsiveMenuButton';
import { useScroll } from '../../../Utils/ScrollContextScrollProvider';

export const B2BPage = () => {

  const [placeholderText, setPlaceholderText] = useState('Search for a product');

  const { registerScrollableElement, unregisterScrollableElement } = useScroll();
  const pageContainerRef = useRef(null);

  const { isVisible } = useScroll();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1400) {
        setPlaceholderText('Search...');
      } else {
        setPlaceholderText('Search for a product');
      }
    };

    // Llamar la función al montar el componente
    handleResize();

    // Agregar event listener para el resize de la ventana
    window.addEventListener('resize', handleResize);

    if (pageContainerRef.current) {
      registerScrollableElement(pageContainerRef.current);
    }

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
      if (pageContainerRef.current) {
        unregisterScrollableElement(pageContainerRef.current);
      }
    }
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={styles.pageContainer}>
      <div className={`${styles.header} ${isVisible ? styles.visible : styles.hidden}`}>
        <img src={AlimamaLogo} />
        <div className={styles.searchBar}>
          <div >
            <SearchIcon className={styles.searchIcon} />
          </div>
          <input type="text" placeholder={placeholderText} className=''>
          </input>
        </div>

        <div className={styles.country}>
          <LanguageIcon />
          <p>Reino de España</p>
        </div>

        <div className={styles.currency}>

          <LanguageIcon />
          <p>EUR</p>

        </div>

        <button className={styles.managementPanel}>Management panel</button>

        <div className={styles.signIn}>
          <Avatar />
          <p>Log in</p>
        </div>
      </div>

      <div className={styles.contentWrapper} ref={pageContainerRef}>

        <div className={styles.presentation}>
          <p className={styles.title}>The leading business-to-business e-commerce platform</p>
          <p className={styles.description}>Whether you are a Chief Executive Officer, Chief Operating Officer or director of a workspace, here you can buy and sell supplies,
            machinery or finished goods.
            Simply log in with your organization profile, choose the currency in which you want to view products and start operating.</p>
        </div>

        <div className={styles.productCategories}>

          <div className={styles.category}>
            <div>
              <ParkOutlinedIcon className={styles.icon} />
            </div>
            <p className={styles.title}>Raw material</p>
            <p className={styles.description}>Raw material forms the foundation of all production, supplying the essential components needed to create machinery and inputs.</p>
          </div>

          <div className={styles.category}>
            <div>
              <SettingsOutlinedIcon className={styles.icon} />
            </div>
            <p className={styles.title}>Inputs</p>
            <p className={styles.description}>Inputs are necessary to produce machinery and final goods. They are the first link in the supply chain.</p>
          </div>

          <div className={styles.category}>
            <div>
              <PrecisionManufacturingOutlinedIcon className={styles.icon} />
            </div>
            <p className={styles.title}>Machinery</p>
            <p className={styles.description}>Machinery is essential for transforming inputs into final goods. It serves as the backbone of production processes, driving efficiency and scalability.</p>
          </div>

          <div className={styles.category}>
            <div>
              <DesktopWindowsOutlinedIcon className={styles.icon} />
            </div>
            <p className={styles.title}>Consumer goods</p>
            <p className={styles.description}>Consumer goods are the final products ready for sale to end-users. They represent the last stage of the supply chain, meeting the demands of everyday life.</p>
          </div>

        </div>

        <div className={styles.testimonialsSection}>

          <div className={styles.testimonial}>
            <img src={Donaldo} className={styles.testimonialImage} alt="Donaldo Trompeta" />
            <blockquote className={styles.testimonialText}>
              <p><i>"I decided to try Alimama.fck to import cheap 'Made in USA' flags, and ended up with 5,000 'Long live Mexico, motherfucker' t-shirts. The best thing is that they allow you to make trade agreements without reading anything. Ideal for those who, like me, prefer to wing it when it comes to international relations. Great platform, great confusion!"</i></p>
              <cite><strong>Donaldo Trompeta</strong><br />CEO of Truth Individual LLC</cite>
            </blockquote>
          </div>

        </div>

        <ResponsiveMenuButton mediaDisplay={'displayUpTo1400'} onClickEvent={()=> setIsDropdownOpen(true)}/>

        <DropdownMenu isOpen={isDropdownOpen} onClose={()=>setIsDropdownOpen(false)}>
          <li>Opción 1</li>
          <li>Opción 2</li>
          <li>Opción 3</li>
        </DropdownMenu>


      </div>
    </div>
  )
}
