import React, { useEffect, useState } from 'react'
import styles from './postreplymobilebutton.module.css'

import Post from './PostIcon.svg'
import Reply from './ReplyIcon.svg'
import { useNavigate } from 'react-router-dom';

import PostReplyModal from '../PostReplyModal/PostReplyModal';

// El type indica si es para publicar un post o una reply. Si es una reply, el targetPostID contiene el ID del post que se responde
export const PostReplyMobileButton = ({ isPost, repliedPostItem }) => {

    const navigate = useNavigate();

    const [active, setActive] = useState(false);
    const handleCloseReplyModal = () => setActive(false);

    // En el onClick hay que hacer navigate a una pantalla donde se deja escribir un post/respuesta, ver el ejemplo de twitter
    return (
        <>
            <button
                onClick={() => {
                    if(isPost){
                        navigate('/social/post')
                    }
                    setActive(true);
                }}
                className={styles.mobileButton}
            >
                {isPost ? <img src={Post} className={styles.mobileButtonIcon} /> : <img src={Reply} className={styles.replyButtonIcon} />}
            </button>

            <section>
                <PostReplyModal open={active} handleClose={handleCloseReplyModal} repliedPost={repliedPostItem} isFeed={isPost ? true : false}/>
            </section>
        </>

    );
};

