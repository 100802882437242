import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';

import HomeFilled from './HomeIconFilled.png'
import HomeOutlined from './HomeIconOutlined.png'

export const mobileNavigationMenu=[
    {
        title:"Social",
        filledIcon:<img src={HomeFilled} style={{width:'23px'}}/>,
        outlinedIcon:<img src={HomeOutlined} style={{width:'23px'}}/>,
        path:"/social"
    },
    {
        title:"Worldview",
        filledIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,    
        outlinedIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/worldview"
    },
    {
        title:"More",
        filledIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<DeviceHubOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/more"
    },
    {
        title:"Office",
        filledIcon:<EmailIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<EmailOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/office"
    },
    {
        title:"Profile",
        filledIcon:<PersonIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/profile/user"
    }
]