import { api } from "../../Config/api"
import { CLEAN_PLAYER_PROFILE_POSTS_FAILURE, CLEAN_PLAYER_PROFILE_POSTS_SUCCESS, DELETE_FAILURE, DELETE_SUCCESS, GET_FEED_FAILURE, GET_FEED_SUCCESS, GET_PLAYER_PROFILE_POSTS_FAILURE, GET_PLAYER_PROFILE_POSTS_REQUEST, GET_PLAYER_PROFILE_POSTS_SUCCESS, GET_POST_RESPONSES_FAILURE, GET_POST_RESPONSES_SUCCESS, LIKE_FAILURE, LIKE_SUCCESS, POST_CREATE_FAILURE, POST_CREATE_SUCCESS, REPOST_FAILURE, REPOST_REQUEST, REPOST_SUCCESS, USER_LIKE_POST_FAILURE, USER_LIKE_POST_SUCCESS } from "./ActionType"


const socialApiEndpointBase = "/api/v2/posts";

export const getFeed = () => async (dispatch) => {
    try {
        const { data } = await api.get(`${socialApiEndpointBase}/feed`)
        console.log("get feed posts : ", data.content)
        dispatch({
            type: GET_FEED_SUCCESS,
            payload: data.content
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: GET_FEED_FAILURE, payload: error.message })
    }
}

// Utilizado cuando se carga el perfil de un nuevo player
export const cleanPlayerPosts = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_PLAYER_PROFILE_POSTS_SUCCESS
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: CLEAN_PLAYER_PROFILE_POSTS_FAILURE, payload: error.message })
    }
}


export const findPostReplies = (postId) => async (dispatch) => {
    try {
        const { data } = await api.get(`${socialApiEndpointBase}/replies/${postId}`)
        console.log("get replies for the post : ", data)
        dispatch({
            type: GET_POST_RESPONSES_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: GET_POST_RESPONSES_FAILURE, payload: error.message })
    }
}

// Puede ser tanto un Post como un reply a un post
export const createPost = (postData, limitPostsCallback) => async (dispatch) => {
    try {
        const { data } = await api.post(`${socialApiEndpointBase}/publish`, postData)
        console.log("published post : ", data)
        dispatch({
            type: POST_CREATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log("error", error)

        // Para cuando se sobreexcede el límite diario de posts
        if (error.response && error.response.status === 429) {
            if (limitPostsCallback) {

                // Condición según el postData, si es un reply o un post
                postData?.isPost ? limitPostsCallback(true): limitPostsCallback(false);
            }
        }

        dispatch({ type: POST_CREATE_FAILURE, payload: error.message })
    }
}

export const repostUnrepost = (postId) => async (dispatch) => {
    try {
        const { data } = await api.patch(`${socialApiEndpointBase}/repost/${postId}`)
        console.log("reposted/unreposted post : ", data)
        dispatch({
            type: REPOST_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: REPOST_FAILURE, payload: error.message })
    }
}

export const likeUnlikePost = (postId) => async (dispatch) => {
    try {
        const { data } = await api.patch(`${socialApiEndpointBase}/like/${postId}`)
        console.log("liked/unliked post : ", data)
        dispatch({
            type: USER_LIKE_POST_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: USER_LIKE_POST_FAILURE, payload: error.message })
    }
}

export const deletePost = (postId) => async (dispatch) => {
    try {
        const { data } = await api.delete(`${socialApiEndpointBase}/${postId}`)
        console.log("deleted post : ", data)
        dispatch({
            type: DELETE_SUCCESS,
            payload: postId
        })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: DELETE_FAILURE, payload: error.message })
    }
}

