import React from 'react';
import styles from './circularprogress.module.css';

const CircularProgress = ({ progress, amountOfCharacters }) => {
    const strokeDasharray = `${progress}, 100`;

    return (
        <svg className={styles.circularProgress} viewBox="0 0 36 36">
            <path
                className={styles.circleBg}
                d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                className={amountOfCharacters > 0 ? amountOfCharacters < 260 ? styles.circleGreen: amountOfCharacters < 280 ? styles.circleOrange : styles.circleRed : styles.circleGray}
                strokeDasharray={strokeDasharray}
                d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
            />
        </svg>
    );
};

export default CircularProgress;
