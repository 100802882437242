import React, { useRef } from 'react'
import WorldviewPage from '../WorldviewPage/WorldviewPage'
import SocialPage from '../SocialPage/SocialPage'
import { Route, Routes } from 'react-router-dom'
import Navigation from '../Navigation/Navigation'
import styles from './worldorder.module.css'
import ProfileContainer from '../ProfileContainer/ProfileContainer'
import { MorePage } from '../MorePage/MorePage'
import { KnowledgePage } from '../KnowledgePage/KnowledgePage'
import { SettingsPage } from '../SettingsPage/SettingsPage'
import { MarketPage } from '../Market/MarketPage'
import { B2BPage } from '../Market/B2BPage/B2BPage'
import { ScrollProvider } from '../../Utils/ScrollContextScrollProvider'
import { Organizations } from '../Organizations/Organizations'

const WorldOrder = () => {

    return (
        <ScrollProvider>
            <div className={styles.container}>

                <Navigation />

                <div className={styles.sectionsContent}>

                    <Routes>
                        <Route path='/*' element={<SocialPage />} />
                        <Route path='/worldview' element={<WorldviewPage />} />

                        <Route path='/social/*' element={<SocialPage />} />

                        <Route path='/market' element={<MarketPage />} />
                        <Route path='/market/b2b' element={<B2BPage />} />

                        <Route path='/organizations' element={<Organizations />} />

                        {/* Para móviles, como directorio para acceder al resto de apartados */}
                        <Route path="/more" element={<MorePage />} />

                        <Route path='/knowledge/*' element={<KnowledgePage />} />
                        <Route path="/profile/*" element={<ProfileContainer />} />
                        <Route path="/settings/*" element={<SettingsPage />} />
                    </Routes>

                </div>

            </div>
        </ScrollProvider>
    );
}

export default WorldOrder