import { Button, Grid, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import * as Action from '../../Store/Auth/Action'

const validationSchema = Yup.object().shape({
  mail: Yup.string().email("Invalid email").required("Mail is required"),
  password: Yup.string().required("Password is required")
})

const SigninForm = ({authError}) => {

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mail: "",
      password: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      const httpCode = await dispatch(Action.loginUser(values))

      if(httpCode >= 400 || httpCode < 500){

        authError();

      }
    }
  })

  const [isMailFocused, setIsMailFocused] = useState(false);

  const handleMailFocus = () => {
    setIsMailFocused(true);
  };

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>

        <Grid item xs={12} style={{ marginTop: '4%' }}>
          <TextField
            fullWidth
            label="Email"
            name='mail'
            variant='outlined'
            size='large'
            value={formik.values.mail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleMailFocus}
            error={formik.touched.mail && Boolean(formik.errors.mail)}
            helperText={formik.touched.mail && formik.errors.mail}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isMailFocused ? 'none':'1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </Grid>

      </Grid>

      <Grid container spacing={2}>

        <Grid item xs={12} style={{ marginTop: '4%' }} >
          <TextField
            fullWidth
            label="Password"
            name='password'
            type='password'
            variant='outlined'
            size='large'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handlePasswordFocus}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isPasswordFocused ? 'none': '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </Grid>
        <Grid className='mt-20' item xs={12}>
          <Button style={{textTransform: 'none', backgroundColor: '#099F5D'}}
            sx={{ borderRadius: "29px", py: "10px"}}
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            ><strong>Sign in</strong></Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SigninForm