import { GET_MAP_FAILURE, GET_MAP_SUCCESS } from "./ActionType";


const initialState = {
    loading: false,
    error: null,

    states: [],
    regions: [],
}

export const mapReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                states: action.payload.states,
                regions: action.payload.regions
            };

        case GET_MAP_FAILURE:
            return { ...state, loading: false, error: action.payload }

        default:
            return state;

    }
}