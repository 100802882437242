// Mapas con los colores para cada cultura y religión principalmente, así como para el resto de distribuciones
// La clave es el nombre del atributo que se devuelve en la respuesta http

export const pieChartDistributionColors = new Map([

    // Cultures

    ['Arab', '#005430'],
    ['Arabic', '#005430'],
    ['Iraqi', '#005430'],
    ['Sirius', '#005430'],
    ['Moroccan', '#005430'],
    ['Algerian', '#005430'],
    ['Libyan', '#005430'],
    ['Tunisian', '#005430'],
    ['Jordanians', '#005430'],
    ['Egyptian', '#005430'],
    ['Palestinian', '#005430'],
    ['Sudanese', '#005430'],
    ['Mauritanian', '#005430'],
    ['Lebanese', '#005430'],

    ['Austroasiatic', '#ffff00'],
    ['Vietnamese', '#ffff00'],
    ['Jemer', '#ffff00'],
    ['Khmuic', '#ffff00'],
    ['Bahnaric', '#ffff00'],
    ['Aslian', '#ffff00'],

    ['Austronesian', '#c99cf5'],
    ['Malagasy', '#c99cf5'],
    ['Philippine', '#c99cf5'],
    ['Malay', '#c99cf5'],
    ['Indonesian', '#c99cf5'],
    ['Micronesian', '#c99cf5'],
    ['Polynesian', '#c99cf5'],
    ['Melanesian', '#c99cf5'],

    ['Baltic', '#0073cf'],
    ['Estonian', '#0073cf'],
    ['Latvian', '#0073cf'],
    ['Lithuanian', '#0073cf'],

    ['Cushitic', '#5ad850'],
    ['Somali', '#5ad850'],
    ['Oromo', '#5ad850'],

    ['Caucasian', '#454a51'],
    ['Georgian', '#454a51'],
    ['Chechen', '#454a51'],
    ['Dagestan', '#454a51'],

    ['Dravinian', '#410064'],
    ['Telegu', '#410064'],
    ['Kannada', '#410064'],
    ['Tamil', '#410064'],
    ['Brahuí', '#410064'],
    ['Malayalam', '#410064'],

    ['Ethiopic', '#a65a42'],
    ['Amharic', '#a65a42'],
    ['Tigrinya', '#a65a42'],

    ['Germanic', '#ffcf00'],
    ['German', '#ffcf00'],
    ['British', '#ffcf00'],
    ['Guyanese', '#ffcf00'],
    ['Swedish', '#ffcf00'],
    ['Norwegian', '#ffcf00'],
    ['Danish', '#ffcf00'],
    ['Icelandic', '#ffcf00'],
    ['Dutch', '#ffcf00'],
    ['Surinamese', '#ffcf00'],
    ['Yankee', '#ffcf00'],
    ['Canadian', '#ffcf00'],
    ['Australian', '#ffcf00'],
    ['NewZealander', '#ffcf00'],
    ['Afrikaans', '#ffcf00'],

    ['Uralic', '#45704f'],
    ['Hungarian', '#45704f'],
    ['Finnish', '#45704f'],
    ['Sami', '#45704f'],

    ['Celtic', '#ff883e'],
    ['Irish', '#ff883e'],
    ['Welsh', '#ff883e'],
    ['Scottish', '#ff883e'],
    ['Breton', '#ff883e'],

    ['IndoAryan', '#ff80df'],
    ['Sinhala', '#ff80df'],
    ['Maldivan', '#ff80df'],
    ['Gujarati', '#ff80df'],
    ['Sindhi', '#ff80df'],
    ['Punjabi', '#ff80df'],
    ['Rajasthani', '#ff80df'],
    ['Hindi', '#ff80df'],
    ['Nepali', '#ff80df'],
    ['Bihari', '#ff80df'],
    ['Bengali', '#ff80df'],
    ['Marathi', '#ff80df'],
    ['Urdu', '#ff80df'],

    ['Iranian', '#da0000'],
    ['Persian', '#da0000'],
    ['Dari', '#da0000'],
    ['Kurdish', '#da0000'],
    ['Balochi', '#da0000'],
    ['Pashto', '#da0000'],

    ['NigerCongo', '#582a03'],
    ['Bantu', '#582a03'],
    ['AdamawaUbangi', '#582a03'],
    ['VoltaNiger', '#582a03'],
    ['Benue', '#582a03'],
    ['Kordofanian', '#582a03'],
    ['AtlanticCongo', '#582a03'],
    ['Gur', '#582a03'],
    ['Kwa', '#582a03'],
    ['Kru', '#582a03'],
    ['Senufo', '#582a03'],
    ['Bambara', '#582a03'],
    ['Bantoid', '#582a03'],

    ['Romance', '#580007'],
    ['Spanish', '#580007'],
    ['Catalan', '#580007'],
    ['Galician', '#580007'],
    ['Portuguese', '#580007'],
    ['French', '#580007'],
    ['Italian', '#580007'],
    ['Romanian', '#580007'],
    ['HispanicCaribbean', '#580007'],
    ['FrancoCaribbean', '#580007'],
    ['Quebecois', '#580007'],
    ['Brazilian', '#580007'],
    ['Argentinian', '#580007'],
    ['Uruguayan', '#580007'],
    ['Paraguayan', '#580007'],
    ['Peruvian', '#580007'],
    ['Chilean', '#580007'],
    ['CentralAmerican', '#580007'],
    ['Venezuelan', '#580007'],
    ['Colombian', '#580007'],
    ['Ecuadorian', '#580007'],
    ['Bolivian', '#580007'],
    ['Mexican', '#580007'],

    ['SinoTibetan', '#d26301'],
    ['Tibetan', '#d26301'],
    ['Burmese', '#d26301'],
    ['Mandarin', '#d26301'],
    ['Karenic', '#d26301'],
    ['Jin', '#d26301'],
    ['Min', '#d26301'],
    ['Wu', '#d26301'],
    ['Xiang', '#d26301'],
    ['Yue', '#d26301'],
    ['Gan', '#d26301'],
    ['Hakka', '#d26301'],
    ['Pinghua', '#d26301'],
    ['Qiangic', '#d26301'],

    ['Slavic', '#b6b4b2'],
    ['Belarusian', '#b6b4b2'],
    ['Bulgarian', '#b6b4b2'],
    ['Polish', '#b6b4b2'],
    ['Czech', '#b6b4b2'],
    ['Slovak', '#b6b4b2'],
    ['Russian', '#b6b4b2'],
    ['Ukrainian', '#b6b4b2'],
    ['Slovenian', '#b6b4b2'],
    ['Croats', '#b6b4b2'],
    ['Serbians', '#b6b4b2'],
    ['Macedonians', '#b6b4b2'],
    ['Bosniaks', '#b6b4b2'],

    ['Tai', '#a020f0'],
    ['Zhuang', '#a020f0'],
    ['Thai', '#a020f0'],
    ['Lao', '#a020f0'],

    ['Turquic', '#cf152d'],
    ['Turkish', '#cf152d'],
    ['Azerbaijani', '#cf152d'],
    ['Uzbek', '#cf152d'],
    ['Kazakh', '#cf152d'],
    ['Turkmen', '#cf152d'],
    ['Kyrgyz', '#cf152d'],
    ['Tatar', '#cf152d'],
    ['Uyghur', '#cf152d'],

    ['Greek', '#0d5eaf'],

    ['Albanian', '#a11329'],

    ['Basque', '#009b48'],

    ['Japanese', '#ffffff'],

    ['Korean', '#0047a0'],

    ['Hebrew', '#0035b9'],

    ['Armenian', '#f2a800'],

    ['Papuan', '#fcd116'],

    ['Eskimo', '#009db0'],

    ['Romani', '#0080ff'],

    ['Berber', '#78be20'],

    ['Chadic', '#fecb00'],

    ['Mongol', '#da2031'],

    ['NiloSaharan', '#e1d5c1'],

    ['Khoisan', '#8a6f63'],

    // Beliefs

    ['Christianity', '#cc8c33'],
    ['Catholicism', '#cc8c33'],
    ['Protestantism', '#cc8c33'],
    ['Orthodox', '#cc8c33'],
    ['CopticOrthodox', '#cc8c33'],
    ['Mormons', '#cc8c33'],

    ['Buddhism', '#b36699'],
    ['Mahayana', '#b36699'],
    ['Vajrayana', '#b36699'],
    ['Therevada', '#b36699'],

    ['Judaism', '#8cd9fa'],
    ['OrthodoxJudaism', '#8cd9fa'],
    ['ConservativeJudaism', '#8cd9fa'],
    ['ReformJudaism', '#8cd9fa'],

    ['Islam', '#1a804d'],
    ['Sunnism', '#1a804d'],
    ['Shiism', '#1a804d'],
    ['Kharijism', '#1a804d'],

    ['Hinduism', '#cc334d'],
    ['Vaishnavism', '#cc334d'],
    ['Shaivism', '#cc334d'],
    ['Shaktism', '#cc334d'],
    ['Smartism', '#cc334d'],

    ['Irreligion', '#ffffff'],

    ['Confucianism', '#ffa666'],

    ['Taoism', '#1a8099'],

    ['Shennism', '#661a33'],

    // Pop types

    ['Workers', '#f80000'],
    ['Unemployed', '#808080'],
    ['Retired', '#3534ce'],
    ['Students', '#01acd8'],
    ['Dependents', '#ffcd00'],

    // Sex

    ['Male', '#0189e1'],
    ['Female', '#ff0163'],

    // Age range

    ['Zero To Ten', '#00a9f7'],
    ['Ten To Twenty', '#009de6'],
    ['Twenty To Thirty', '#008ccd'],
    ['Thirty To Forty', '#0076ad'],
    ['Forty To Fifty', '#006593'],
    ['Fifty To Sixty', '#00567d'],
    ['Sixty To Seventy', '#004666'],
    ['Seventy To Eighty', '#00354d'],
    ['Eighty To Ninety', '#002738'],
    ['Ninety To One Hundred', '#00161f'],


    // Qualification

    ['Illiterate', '#ffdc00'],
    ['Primary', '#e1c200'],
    ['Secondary', '#c8ad00'],
    ['Undergraduate', '#b09800'],
    ['Vocational', '#9c8700'],
    ['Graduate', '#827100'],
    ['Postgraduate', '#635600'],

    // Race
    ['White', '#ffffff'],
    ['Black', '#47391d'],
    ['Asian', '#ecd5a9'],
    ['CentralAsian', '#dabb8f'],
    ['Semitic', '#808186'],
    ['Mestizo', '#c6813c'],
    ['Amerindian', '#51541d'],
    ['Oceanic', '#011e48'],
    ['Hindustani', '#ff681a'],

    // Others

    ['Others', '#767b80'],


]);