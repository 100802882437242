import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from '../../../../Store/Auth/Action';
import styles from './skillsModal.module.css';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import BoltIcon from '@mui/icons-material/Bolt';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { levelUpSkill } from '../../../../Store/PlayerProfile/Action';
import { FormattedDate } from '../../../../Utils/FormattedDate';



export const SkillsModal = ({ open, handleClose }) => {
    const [uploading, setUploading] = React.useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        dispatch(updateUserProfile(values));
        console.log("handleSubmit", values);
    }

    const { ownPlayerProfile } = useSelector(store => store.playerProfile);

    const handleLevelUpSkill = (skillType) => {
        dispatch(levelUpSkill(skillType));
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } } }}
            >
                <div className={styles.modalStyle}>

                    <div className={styles.modalContent}>

                        <div className={styles.header}>
                            <div className={styles.headerLeft}>
                                <p className={styles.headerText}><strong>Skills</strong></p>
                            </div>
                            <IconButton onClick={handleClose} aria-label='delete'>
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </div>



                        <div className={styles.skillContainer}>

                            <div className={styles.skillLevel}>
                                <LightbulbIcon style={{ color: 'white', fontSize: '40px' }} />
                                <div className={styles.skillInfo}>
                                    <p>Intelligence</p>
                                    <p><strong>{ownPlayerProfile?.skills?.intelligence}</strong></p>
                                </div>
                            </div>

                            {ownPlayerProfile?.skills?.improvingSkill !== 'INTELLIGENCE'
                                ? <div style={{ display: 'flex', flexDirection: 'row', gap: '0.3rem' }}>
                                    <AccessTimeFilledIcon />
                                    <p>{ownPlayerProfile?.skills?.requiredHoursToLevelUpIntelligence} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredMinutesToLevelUpIntelligence} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredSecondsToLevelUpIntelligence}</p>
                                    <p><small>(H/M/S)</small></p>
                                </div>
                                : <FormattedDate date={ownPlayerProfile?.skills?.momentOfImprovement} />}

                            {ownPlayerProfile?.skills?.improvingSkill !== 'INTELLIGENCE'
                                ? <button className={styles.levelUpButton} onClick={ownPlayerProfile?.skills?.improvingSkill ? undefined : () => handleLevelUpSkill('INTELLIGENCE') }>Level Up</button>
                                : <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}><p style={{ color: '#099f5d' }}><strong>IMPROVING</strong></p></div>}
                        </div>

                        <div className={styles.skillContainer}>
                            <div className={styles.skillLevel}>
                                <FitnessCenterIcon style={{ color: 'white', fontSize: '40px' }} />
                                <div className={styles.skillInfo}>
                                    <p>Strength</p>
                                    <p><strong>{ownPlayerProfile?.skills?.strength}</strong></p>
                                </div>
                            </div>

                            {ownPlayerProfile?.skills?.improvingSkill !== 'STRENGTH'
                                ? < div style={{ display: 'flex', flexDirection: 'row', gap: '0.3rem' }}>
                                    <AccessTimeFilledIcon />
                                    <p>{ownPlayerProfile?.skills?.requiredHoursToLevelUpStrength} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredMinutesToLevelUpStrength} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredSecondsToLevelUpStrength}</p>
                                    <p><small>(H/M/S)</small></p></div>
                                : <FormattedDate date={ownPlayerProfile?.skills?.momentOfImprovement} />}

                            {ownPlayerProfile?.skills?.improvingSkill !== 'STRENGTH'
                                ? <button className={styles.levelUpButton} onClick={ownPlayerProfile?.skills?.improvingSkill ? undefined : () => handleLevelUpSkill('STRENGTH')}>Level Up</button>
                                : <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}><p style={{ color: '#099f5d' }}><strong>IMPROVING</strong></p></div>}
                        </div>



                        <div className={styles.skillContainer}>
                            <div className={styles.skillLevel}>
                                <BoltIcon style={{ color: 'white', fontSize: '40px' }} />
                                <div className={styles.skillInfo}>
                                    <p>Endurance</p>
                                    <p><strong>{ownPlayerProfile?.skills?.endurance}</strong></p>
                                </div>
                            </div>

                            {ownPlayerProfile?.skills?.improvingSkill !== 'ENDURANCE'
                                ? <div style={{ display: 'flex', flexDirection: 'row', gap: '0.3rem' }}>
                                    <AccessTimeFilledIcon />
                                    <p>{ownPlayerProfile?.skills?.requiredHoursToLevelUpEndurance} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredMinutesToLevelUpEndurance} :</p>
                                    <p>{ownPlayerProfile?.skills?.requiredSecondsToLevelUpEndurance}</p>
                                    <p><small>(H/M/S)</small></p></div>
                                : <FormattedDate date={ownPlayerProfile?.skills?.momentOfImprovement} />}

                            {ownPlayerProfile?.skills?.improvingSkill !== 'ENDURANCE'
                                ? <button className={styles.levelUpButton} onClick={ownPlayerProfile?.skills?.improvingSkill ? undefined : () => handleLevelUpSkill('ENDURANCE')}>Level Up</button>
                                : <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}><p style={{ color: '#099f5d' }}><strong>IMPROVING</strong></p></div>}


                        </div>

                    </div>
                </div>
            </Modal >
        </div >
    );
}
