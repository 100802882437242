import axios from "axios"
import { API_BASE_URL, api, updateAuthorizationHeader } from "../../Config/api"
import { FIND_USER_BY_ID_FAILURE, FIND_USER_BY_ID_SUCCESS, FOLLOW_USER_FAILURE, FOLLOW_USER_SUCCESS, GET_USER_PROFILE_FAILURE, GET_USER_PROFILE_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS, LOGOUT, REGISTER_USER_FAILURE, REGISTER_USER_SUCCESS, RELOAD_PAGE_FAILURE, RELOAD_PAGE_SUCCESS, UPDATE_USER_PROFILE_FAILURE, UPDATE_USER_PROFILE_SUCCESS } from "./ActionType"
import { getPlayerProfile } from "../PlayerProfile/Action"

export const reloadPage = (jwt) => async (dispatch) => {
    try {
        const JWT = localStorage.getItem("jwt")

        if (!JWT || typeof JWT !== "string") {
            throw new Error("JWT not found or invalid in localStorage");
        }

        updateAuthorizationHeader(JWT);
        dispatch({ type: RELOAD_PAGE_SUCCESS, payload: JWT })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: RELOAD_PAGE_FAILURE, payload: error.message })
        dispatch({ type: LOGOUT }) // Despachar acción para vaciar el estado
    }
}

export const loginUser = (loginData) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/user/login`, loginData)

        const { data, status } = response;

        if (data.JWT && typeof data.JWT === "string") {
            localStorage.setItem("jwt", data.JWT)

            // Actualizar el encabezado de autorización en la instancia de axios
            updateAuthorizationHeader(data.JWT);
        }
        dispatch({ type: LOGIN_USER_SUCCESS, payload: data.JWT })

        // Devuelve el código de estado HTTP
        return status;
    } catch (error) {
        console.log("error", error)
        dispatch({ type: LOGIN_USER_FAILURE, payload: error.message })
        return error.response?.status || 500;
    }
}

export const loginOrSignupWithGoogle = (googleToken, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/user/login-signup-with-google?token=${googleToken}`)


        const { data, status } = response;

        if (data.googleToken) {
            navigate(`/sign-up?token=${data.googleToken}`)
        }

        if (data.JWT && typeof data.JWT === "string") {
            localStorage.setItem("jwt", data.JWT)

            // Actualizar el encabezado de autorización en la instancia de axios
            updateAuthorizationHeader(data.JWT);

            dispatch({ type: LOGIN_USER_SUCCESS, payload: data.JWT })

        }

        // Devuelve el código de estado HTTP, el jwt en caso de ser exitoso el login o el googleToken en caso de que se precise de hacer un signUp con ese googleToken
        return {
            statusCode: status,
            jwt: data.JWT,
            googleToken: data.googleToken
        }

    } catch (error) {
        console.log("error", error)
        dispatch({ type: LOGIN_USER_FAILURE, payload: error.message })
        return error.response?.status || 500;
    }
}

export const registerUser = (registerData) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/api/user/signup`, registerData)


        dispatch({ type: REGISTER_USER_SUCCESS, payload: data })

        // El registro ha sido exitoso
        return true;

    } catch (error) {
        console.log("error", error)
        dispatch({ type: REGISTER_USER_FAILURE, payload: error.message })

        // El registro ha fallado
        return false;
    }
}

export const validateUserMail = (mailToken, navigate) => async (dispatch) => {
    try {

        // Lo primero de todo redirijir al Inicio, si se valida el token y se guarda el jwt, entonces ya se cambiará la pantalla a la del juego
        navigate('/');

        const tokenJSON = {
            token: mailToken
        }

        const { data } = await axios.post(`${API_BASE_URL}/api/user/signup/validate`, tokenJSON, {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        );

        if (data.JWT) {
            localStorage.setItem("jwt", data.JWT)
        }

        dispatch({ type: LOGIN_USER_SUCCESS, payload: data.JWT })

    } catch (error) {
        console.log("error", error)
        dispatch({ type: REGISTER_USER_FAILURE, payload: error.message })
    }
}

/*
// Si el Id del Params coincide con el guardado en el Estado, entonces se ejecuta este método, si no, se ejecuta el del PlayerProfile action
export const getPlayerProfile = (jwt)=>async(dispatch)=>{
    try{
        const {data}=await axios.get(`${API_BASE_URL}/api/player/profile`, {
            headers: {
                "Authorization":`Bearer ${jwt}`
            }
        })

        dispatch({type:GET_USER_PROFILE_SUCCESS, payload:data})
    }catch(error){
        console.log("error", error)
        dispatch({type:GET_USER_PROFILE_FAILURE, payload:error.message})
    }
}

// Si el ID del Params no coincide con el guardado en el Estado, entonces se ejecuta este método
export const getPlayerProfileById = (userId)=>async(dispatch)=>{
    try{
        const {data}=await api.get(`/api/player/profile/${userId}`
        )

        dispatch({type:GET_USER_PROFILE_SUCCESS, payload:data})
    }catch(error){
        console.log("error", error)
        dispatch({type:GET_USER_PROFILE_FAILURE, payload:error.message})
    }
}

*/

export const findUserById = (userId) => async (dispatch) => {
    try {
        const { data } = await api.get(`/api/player/profile/${userId}`
        )

        dispatch({ type: FIND_USER_BY_ID_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: FIND_USER_BY_ID_FAILURE, payload: error.message })
    }
}

export const updateUserProfile = (reqData) => async (dispatch) => {
    try {
        const { data } = await api.post(`/api/player/profile/update`, reqData)

        dispatch({ type: FOLLOW_USER_SUCCESS, payload: data })
    } catch (error) {
        console.log("error", error)
        dispatch({ type: FOLLOW_USER_FAILURE, payload: error.message })
    }
}

export const followUser = (userId) => async (dispatch) => {
    try {
        const { data } = await api.put(`/api/player/follow/${userId}`)

        dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: error.message })
    }
}

export const logout = () => async (dispatch) => {

    localStorage.removeItem("jwt")

    dispatch({ type: LOGOUT, payload: null })
}

