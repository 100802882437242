import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Authentication from './Components/Authentication/Authentication';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Action from './Store/PlayerProfile/Action'
import Verify from './Components/Authentication/Verify';
import ActivateAccountPage from './Components/Authentication/ActivateAccountPage';
import WorldOrder from './Components/WorldOrder/WorldOrder';
import { reloadPage } from './Store/Auth/Action';
import { TermsAndPolicies } from './Components/TermsAndPolicies/TermsAndPolicies';

function App() {

  const superNavigate = useNavigate();

  const JWT = localStorage.getItem("jwt")
  const { auth } = useSelector(store => store)
  const dispatch = useDispatch()



  useEffect(() => {

    const initializeApp = async () => {

      await dispatch(reloadPage(JWT));

      if (auth.JWT) {
        dispatch(Action.getPlayerProfile(JWT))
        superNavigate('/worldview')
      }
    }

    initializeApp();

  }, [JWT])

  return (
      <div className="dark:bg-black dark:text-white">

        <Routes>
          <Route path='/*' element={JWT ? <WorldOrder navigate={superNavigate} /> : <Authentication navigate={superNavigate} />}></Route>
          <Route path='/verify' element={<Verify />}></Route>
          <Route path='/activate-account' element={<ActivateAccountPage navigate={superNavigate} />}></Route>
          <Route path='/terms-and-policies/*' element={<TermsAndPolicies />}></Route>
          <Route path='/sign-up' element={<p>Próximamente, por ahora debes crear cuenta con el botón "Create Account"</p>}></Route>
        </Routes>

      </div>
  );
}

export default App;
