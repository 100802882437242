import React from 'react'
import styles from './responsivemenubutton.module.css'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

export const ResponsiveMenuButton = ({mediaDisplay, onClickEvent}) => {
    return (
        <button onClick={onClickEvent} className={`${styles.menuButton} ${styles[mediaDisplay]}`}>
            <MenuOutlinedIcon className={styles.menuButtonIcon} />
        </button>
    )
}
