import React from 'react'
import styles from './settingspage.module.css'
import { Route, Routes } from 'react-router-dom'

export const SettingsPage = () => {
  return (
    <div className={styles.socialContainer}>

      <div className={styles.settingsList}>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
        <p>ejemplo</p>
      
      </div>

      <div className={styles.settingsSection}>

        <Routes>
          <Route path="/org/:username" element={<p>En desarrollo</p>} />
        </Routes>


      </div>

    </div>
  )
}
