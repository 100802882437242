import React, { useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import PublicationCard from '../PublicationCard/PublicationCard';
import SocialRightPart from '../RightPart/SocialRightPart';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, findPostReplies } from '../../../Store/Post/Action';
import { store } from '../../../Store/Store';
import styles from './publicationDetails.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageIcon from '@mui/icons-material/Image';
import PollIcon from '@mui/icons-material/Poll';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Avatar } from '@mui/material';
import { uploadToCloudinary } from '../../../Utils/UploadToCloudinary';
import CircularProgress from '../CircularProgress/CircularProgress';
import { LimitPostsModal } from '../Feed/Modals/LimitPostsModal';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SendIcon from '@mui/icons-material/Send';
import { PostReplyMobileButton } from '../PostReplyMobileButton/PostReplyMobileButton';
import CloseIcon from '../../Assets/CloseIcon.svg'

const PublicationDetails = () => {
    const navigate = useNavigate();
    const handleBack = () => navigate(-1);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { post } = useSelector(store => store);
    const [selectImage, setSelectedImage] = useState("");

    const [charCount, setCharCount] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [uploadingImage, setUploadingImage] = useState(false);

    const { profileImageUrl } = useSelector(store => store.auth);

    const [openLimitPostsModal, setOpenLimitPostsModal] = useState({ open: false, isRequestAPost: false });
    const handleOpenLimitPostsModal = (isPost) => setOpenLimitPostsModal({ open: true, isRequestAPost: isPost });
    const handleCloseLimitPostsModal = () => setOpenLimitPostsModal({ ...openLimitPostsModal, open: false });

    // Para actualizar la página en tiempo real al dar like
    useEffect(() => {
        setIsLoading(true);
        dispatch(findPostReplies(id))
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        console.log("Publication Details creado");
    }, [id, post.feedLike, post.feedRepost, post.feedReply, dispatch]);

    const handleSubmit = (values, actions) => {
        dispatch(createPost(values, handleOpenLimitPostsModal));
        actions.resetForm();
        setSelectedImage("");
        console.log("values", values);
    };

    const validationSchema = Yup.object().shape({
        content: Yup.string().required("Post text is required").max(280, 'Cannot exceed 280 characters')
    });

    const formik = useFormik({
        initialValues: {
            content: "",
            isPost: false,
            isReply: true,
            replyForId: id,
            image: [],
            video: [],
        },
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleSelectImage = async (event) => {
        setUploadingImage(true);
        const imgUrl = await uploadToCloudinary(event.target.files[0]);
        formik.setFieldValue("image", imgUrl);
        setSelectedImage(imgUrl);
        setUploadingImage(false);
    };

    const autoResize = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setCharCount(value.length);
        formik.handleChange(e);
    };

    const progress = (charCount / 280) * 100;

    // Mantienen el estado de si se ve el input para poner la URL de imagen y de vídeo
    const [showImageInput, setShowImageInput] = useState(false);
    const [showVideoInput, setShowVideoInput] = useState(false);

    const handleAddMedia = (type, url) => {
        if ((formik.values.image.length + formik.values.video.length) < 2) {
            formik.setFieldValue(type, [...formik.values[type], url]);
            formik.setFieldValue(`${type}Url`, '');
        }

        if (type === 'video') {
            setShowVideoInput(false)
        } else {
            setShowImageInput(false)
        }
    };

    const handleRemoveMedia = (index, type) => {
        const updatedMedia = [...formik.values[type]];
        updatedMedia.splice(index, 1);
        formik.setFieldValue(type, updatedMedia);
    };

    const renderMediaPreviews = () => {
        const allMedia = [
            ...formik.values.image.map(url => ({ url, type: 'image' })),
            ...formik.values.video.map(url => ({ url, type: 'video' }))
        ];

        return allMedia.map((media, index) => (
            <div key={index} className={styles.mediaPreview}>
                <div className={styles.mediaWrapper}>
                    {media.url.includes('youtube.com') ? (
                        <div className={styles.videoWrapper}>
                            <iframe
                                width="400"
                                height="225"
                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(media.url)}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                className={styles.responsiveIframe}
                            ></iframe>
                        </div>
                    ) : (
                        <img src={media.url} alt={`Media ${index + 1}`} className={styles.responsiveImage} />
                    )}
                    <button
                        className={styles.removeMediaButton}
                        onClick={() => handleRemoveMedia(index, media.type)}
                        type="button"
                    >
                        <img src={CloseIcon} className={styles.closeIcon} />
                    </button>
                </div>
            </div>
        ));
    };

    // Función auxiliar para extraer el ID del video de YouTube de la URL
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    return (
        <section style={{ width: '100%' }}>
            {isLoading ? (
                <></>
            ) : (
                <div>
                    <div className={styles.socialContainer} style={{ border: '1px solid #2c2f32', borderTop: 'none' }}>
                        <section className={styles.headerSection}>
                            <KeyboardBackspaceIcon className={styles.icon} onClick={handleBack} />
                            <h1 className={styles.title}>Post</h1>
                        </section>

                        <section>
                            {/* Cambiar por el estilo de twitter*/}
                            <PublicationCard item={post.postDetails} key={post.postDetails?.id} />
                        </section>

                        <div className={styles.formContainer}>
                            <Avatar alt="username" src={profileImageUrl} />

                            <div className={styles.formContent}>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className={styles.inputAndError}>
                                        <textarea
                                            name='content'
                                            placeholder='Answer a lie with another lie...'
                                            className={styles.inputField}
                                            {...formik.getFieldProps("content")}
                                            onInput={(e) => {
                                                autoResize(e);
                                                handleInputChange(e);
                                            }}
                                            maxLength={280}
                                            rows={1}
                                        />
                                        {formik.errors.content && formik.touched.content && (
                                            <span className={styles.errorText}>{formik.errors.content}</span>
                                        )}
                                    </div>

                                    <div className={styles.imageVideoContainer}>
                                        {showImageInput && (
                                            <div className={styles.imageVideoInputContainer}>
                                                <input
                                                    type="text"
                                                    name="imageUrl"
                                                    placeholder="Imgur image URL..."
                                                    className={styles.imageVideoInputField}
                                                    {...formik.getFieldProps('imageUrl')}
                                                />
                                                <button
                                                    className={styles.imageVideoInputButton}
                                                    type="button"
                                                    onClick={() => handleAddMedia('image', formik.values.imageUrl)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                        {showVideoInput && (
                                            <div className={styles.imageVideoInputContainer}>
                                                <input
                                                    type="text"
                                                    name="videoUrl"
                                                    placeholder="YouTube video URL..."
                                                    className={styles.imageVideoInputField}
                                                    {...formik.getFieldProps('videoUrl')}
                                                />
                                                <button className={styles.imageVideoInputButton} type="button" onClick={() => handleAddMedia('video', formik.values.videoUrl)}>Add</button>
                                            </div>
                                        )}
                                        {(formik.errors.image || formik.errors.video) && (
                                            <span className={styles.errorText}>{formik.errors.image || formik.errors.video}</span>
                                        )}
                                    </div>
                                    
                                    <div className={styles.mediaContent}>
                                        {renderMediaPreviews()}
                                    </div>

                                    <div className={styles.formActions}>
                                        <div className={styles.actionItems}>
                                            <ImageIcon
                                                className={styles.iconText}
                                                onClick={() => setShowImageInput(!showImageInput)} />
                                            <YouTubeIcon
                                                className={styles.iconText}
                                                onClick={() => setShowVideoInput(!showVideoInput)} />
                                            <PollIcon className={styles.iconText} />
                                            <TagFacesIcon className={styles.iconText} />
                                        </div>

                                        <div className={styles.circularProgressContainer}>
                                            <CircularProgress progress={progress} amountOfCharacters={charCount} />
                                        </div>

                                        <div>
                                            <button
                                                className={styles.socialPostButton}
                                                type="submit">
                                                <strong>Reply</strong>
                                            </button>
                                            <button
                                                className={styles.mobileSocialPostButton}
                                                type="submit">
                                                <SendIcon className={styles.iconText} />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    {selectImage && <img className={styles.selectedImage} src={selectImage} alt='' />}
                                </div>
                            </div>
                        </div>

                        <section>
                            {post.postReplies.map((item) => (
                                <PublicationCard item={item} key={item.id} openLimitPostsModal={handleOpenLimitPostsModal} />
                            ))}
                        </section>
                    </div>


                    <section>
                        <LimitPostsModal open={openLimitPostsModal.open} handleClose={handleCloseLimitPostsModal} isPost={openLimitPostsModal.isRequestAPost} />
                    </section>

                    <PostReplyMobileButton isPost={false} repliedPostItem={post.postDetails} />

                </div>
            )}
        </section>
    );
};

export default PublicationDetails;
