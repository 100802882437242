import { useEffect, useRef } from "react";
import styles from './dropdown.module.css'

// Componente Dropdown
export const DropdownMenu = ({ isOpen, onClose, children }) => {
    const dropdownRef = useRef(null);

    // Detecta los clics fuera del menú
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose(); // Si el clic fue fuera del dropdown, lo cierra
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null; // No renderiza el dropdown si no está abierto
    }

    return (
        <div className={styles.dropdownContainer}>
            <div ref={dropdownRef} className={styles.dropdownMenu}>
                <ul className={styles.menuList}>
                    {children} {/* Aquí van los elementos que quieras mostrar dentro del menú */}
                </ul>
            </div>
        </div>
    );
};