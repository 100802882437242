import React from "react";

import styles from "./piechartcomponent.module.css"
import { PieChart } from "@mui/x-charts";
import { pieChartDistributionColors } from "./distributionColors";

export const PieChartComponent = ({ name, distributionData, absoluteNumbersDistributionData }) => {

  // Generar el array de colores basados en los labels de absoluteNumbersDistributionData
  const colors = absoluteNumbersDistributionData.map((item) => pieChartDistributionColors.get(item.label) || '#cccccc'); // '#cccccc' es un fallback si no encuentra el color

  return (
    <div className={styles.pieChartComponent}>
      <p className={styles.chartTitle}>{name}</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          paddingTop: "10px"
        }}
      >
        <PieChart
          colors={colors} 
          series={[
            {
              data: distributionData.length ? distributionData : [],
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 2,
              cornerRadius: 5,
              valueFormatter: (value) => `${(value?.value).toFixed(2)}%`},
          ]}
          slotProps={{ legend: { hidden: true } }}
          width={15}
          height={200}
        />
      </div>
      <div className={styles.registryContainer}>

        {absoluteNumbersDistributionData.map((item, index) => (
          <div key={index} className={styles.registry}>
            <div className={styles.colorAndGroupContainer}>
              <div className={styles.square} style={{backgroundColor:`${pieChartDistributionColors.get(item.label)}`}}></div>
              <p>{item.label}</p>
            </div>
            <p>{item.value >= 1000000 ? (item.value / 1000000).toFixed(1) + 'M' : (item.value / 1000).toFixed(1) + 'K'}</p>
          </div>
        ))}

      </div>
    </div>
  );
};