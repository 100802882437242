import React, { useEffect, useRef } from 'react'
import SocialRightPart from './RightPart/SocialRightPart'
import { Routes, Route, useMatch } from 'react-router-dom'
import PublicationDetails from './PublicationDetails/PublicationDetails'
import Feed from './Feed/Feed'
import styles from './socialpage.module.css'
import { useScroll } from '../../Utils/ScrollContextScrollProvider'

const SocialPage = () => {

  const { registerScrollableElement, unregisterScrollableElement } = useScroll();
    const pageContainerRef = useRef(null);

    useEffect(() => {
        if (pageContainerRef.current) {
            registerScrollableElement(pageContainerRef.current);
        }

        // Limpiar el event listener al desmontar el componente
        return () => {
            if (pageContainerRef.current) {
                unregisterScrollableElement(pageContainerRef.current);
            }
        }
    }, []);

  return (

    <div className={styles.socialContainer} ref={pageContainerRef}>



      <div className={styles.feedContainer}>
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/post" element={<Feed />} />
          <Route path="/posts/:id" element={<PublicationDetails />} />
        </Routes>
        
      </div>


      <div className={styles.socialRightPart}>

        <SocialRightPart />

      </div>

    </div>



  );


}

export default SocialPage
