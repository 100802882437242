export const POST_CREATE_REQUEST="POST_CREATE_REQUEST"
export const POST_CREATE_SUCCESS="POST_CREATE_SUCCESS"
export const POST_CREATE_FAILURE="POST_CREATE_FAILURE"

export const POST_DELETE_REQUEST="POST_DELETE_REQUEST"
export const POST_DELETE_SUCCESS="POST_DELETE_SUCCESS"
export const POST_DELETE_FAILURE="POST_DELETE_FAILURE"

export const GET_FEED_REQUEST="GET_FEED_REQUEST"
export const GET_FEED_SUCCESS="GET_FEED_SUCCESS"
export const GET_FEED_FAILURE="GET_FEED_FAILURE"

export const GET_PLAYER_PROFILE_POSTS_REQUEST="GET_PLAYER_PROFILE_POSTS_REQUEST"
export const GET_PLAYER_PROFILE_POSTS_SUCCESS="GET_PLAYER_PROFILE_POSTS_SUCCESS"
export const GET_PLAYER_PROFILE_POSTS_FAILURE="GET_PLAYER_PROFILE_POSTS_FAILURE"

export const CLEAN_PLAYER_PROFILE_POSTS_REQUEST="CLEAN_PLAYER_PROFILE_POSTS_REQUEST"
export const CLEAN_PLAYER_PROFILE_POSTS_SUCCESS="CLEAN_PLAYER_PROFILE_POSTS_SUCCESS"
export const CLEAN_PLAYER_PROFILE_POSTS_FAILURE="CLEAN_PLAYER_PROFILE_POSTS_FAILURE"

export const GET_POST_RESPONSES_REQUEST="GET_POST_RESPONSES_REQUEST"
export const GET_POST_RESPONSES_SUCCESS="GET_POST_RESPONSES_SUCCESS"
export const GET_POST_RESPONSES_FAILURE="GET_POST_RESPONSES_FAILURE"

export const USER_LIKE_POST_REQUEST="USER_LIKE_POST_REQUEST"
export const USER_LIKE_POST_SUCCESS="USER_LIKE_POST_SUCCESS"
export const USER_LIKE_POST_FAILURE="USER_LIKE_POST_FAILURE"

export const REPLY_POST_REQUEST="REPLY_POST_REQUEST"
export const REPLY_POST_SUCCESS="REPLY_POST_SUCCESS"
export const REPLY_POST_FAILURE="REPLY_POST_FAILURE"

export const SEARCH_POST_REQUEST="SEARCH_POST_REQUEST"
export const SEARCH_POST_SUCCESS="SEARCH_POST_SUCCESS"
export const SEARCH_POST_FAILURE="SEARCH_POST_FAILURE"

export const REPOST_REQUEST="REPOST_REQUEST"
export const REPOST_SUCCESS="REPOST_SUCCESS"
export const REPOST_FAILURE="REPOST_FAILURE"

export const LIKE_REQUEST="LIKE_REQUEST"
export const LIKE_SUCCESS="LIKE_SUCCESS"
export const LIKE_FAILURE="LIKE_FAILURE"

export const DELETE_REQUEST="DELETE_REQUEST"
export const DELETE_SUCCESS="DELETE_SUCCESS"
export const DELETE_FAILURE="DELETE_FAILURE"