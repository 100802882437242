export const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

export const replaceUnderscoresWithSpaces = (text) => {
    if(!text) return '';
    let finalText =  text.replace(/_/g, ' ');
    return capitalizeFirstLetter(finalText);
}

// Ejemplo de uso:
const originalText = "Este_es_un_ejemplo_de_texto_con_guiones_bajos";
const newText = replaceUnderscoresWithSpaces(originalText);
console.log(newText); // Output: "Este es un ejemplo de texto con guiones bajos"
