import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from '../../../Store/Auth/Action';

import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';

import styles from './profilemodal.module.css'
import { setProfilePhotoCanvasPreview } from './setProfilePhotoCanvasPreview';
import { updateProfile } from '../../../Store/PlayerProfile/Action';
import { dataUrlToFile } from './dataUrlToFile';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#000000',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: 4
};


export default function ProfileModal({ open, handleClose }) {
  // const [open, setOpen] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const dispatch = useDispatch();

  const { ownPlayerProfile } = useSelector(store => store.playerProfile);

  const [profilePhotoError, setProfilePhotoError] = useState('');

  const profilePhotoRef = React.useRef(null);
  const previewProfilePhotoCanvasRef = React.useRef(null);


  const handleSubmit = async (values) => {

    handleClose();

    const formData = new FormData();

    const profileData = {
      name: values.name,
      about: values.about,
      socialIdeology: values.socialIdeology,
      socialIdeologyAttribute: values.socialIdeologyAttribute,
      economicIdeology: values.economicIdeology,
      powerStructureIdeology: values.powerStructureIdeology,
      formOfGovernmentIdeology: values.formOfGovernmentIdeology
    };

    // Convert data URL to file
    // Verificar que los valores no sean null antes de convertirlos
    const profileImageFile = values.image ? dataUrlToFile(values.image, 'profileImage.png') : null;
    const backgroundImageFile = backgroundImage // values.backgroundImage ? dataUrlToFile(values.backgroundImage, 'backgroundImage.png') : null;


    formData.append('profileData', new Blob([JSON.stringify(profileData)], { type: 'application/json' }));
    formData.append('profileImageFile', profileImageFile);
    formData.append('backgroundImageFile', backgroundImageFile);

    dispatch(updateProfile(formData));
  }

  // Aquí abajo poner los valores ya asignados del player traídos del backend
  const formik = useFormik({
    initialValues: {
      name: '',
      about: '',
      backgroundImage: '',
      image: '',
      socialIdeology: '',
      socialIdeologyAttribute: '',
      economicIdeology: '',
      formOfGovernmentIdeology: '',
      powerStructureIdeology: '',
    },
    onSubmit: handleSubmit
  })

  const handleImageChange = (event) => {
    setUploading(true);
    const { name } = event.target
    const file = event.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      setBackgroundImage(file);
      formik.setFieldValue('backgroundImage', url);
    }


    setUploading(false);
  }

  // Imagen background en caso de haber sido cargada para ser cambiada
  const [backgroundImage, setBackgroundImage] = useState(null);

  const [isNameFocused, setIsNameFocused] = useState(false);
  const handleNameFocus = () => {
    setIsNameFocused(true);
    console.log(ownPlayerProfile?.targetUser?.name)
  };

  const [isAboutFocused, setIsAboutFocused] = useState(false);
  const handleAboutFocus = () => {
    setIsAboutFocused(true);
  };

  const [isSocialIdeologyFocused, setIsSocialIdeologyFocused] = useState(false);
  const handleSocialIdeologyFocus = () => {
    setIsSocialIdeologyFocused(true);
  };

  const [isSocialIdeologyAttributeFocused, setIsSocialIdeologyAttributeFocused] = useState(false);
  const handleSocialIdeologyAttributeFocus = () => {
    setIsSocialIdeologyAttributeFocused(true);
  };

  const [isEconomicIdeologyFocused, setIsEconomicIdeologyFocused] = useState(false);
  const handleEconomicIdeologyFocus = () => {
    setIsEconomicIdeologyFocused(true);
  };

  const [isFormOfGovernmentPreferenceFocused, setIsFormOfGovernmentPreferenceFocused] = useState(false);
  const handleFormOfGovernmentPreferenceFocus = () => {
    setIsFormOfGovernmentPreferenceFocused(true);
  };

  const [isPowerStructurePreferenceFocused, setIsPowerStructurePreferenceFocused] = useState(false);
  const handlePowerStructurePreferenceFocus = () => {
    setIsPowerStructurePreferenceFocused(true);
  };


  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();

  const onSelectProfilePhotoFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (profilePhotoError) setProfilePhotoError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setProfilePhotoError("Image must be at least 150 x 150 pixels");
          return setImgSrc("")
        }
      })

      setImgSrc(imageUrl)
    });
    reader.readAsDataURL(file);
  };

  const onProfilePhotoLoad = (e) => {

    const { width, height } = e.currentTarget
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height)
    setCrop(centeredCrop);
  }


  React.useEffect(() => {
    if (ownPlayerProfile) {
      formik.setValues({
        name: ownPlayerProfile?.targetUser?.name || '',
        about: ownPlayerProfile?.targetUser?.about || '',
        backgroundImage: ownPlayerProfile?.targetUser?.backgroundImageUrl || '',
        image: ownPlayerProfile?.targetUser?.profileImageUrl || '',
        socialIdeology: ownPlayerProfile?.socialIdeology || '',
        socialIdeologyAttribute: ownPlayerProfile?.socialIdeologyAttribute || '',
        economicIdeology: ownPlayerProfile?.economicIdeology || '',
        formOfGovernmentIdeology: ownPlayerProfile?.formOfGovernmentIdeology || '',
        powerStructureIdeology: ownPlayerProfile?.powerStructureIdeology || '',
      });
    }
  }, [ownPlayerProfile]);



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } } }}
      >
        <Box sx={style} className='overflow-y-scroll overflow-x-hidden h-[80vh]'>




          <form onSubmit={formik.handleSubmit}>

            <div className='flex items-center justify-between' style={{ marginBottom: '2%' }}>
              <div className='flex items-center space-x-3'>
                <p style={{ fontSize: '19px' }}><strong>Edit profile</strong></p>
              </div>

              <div className={styles.saveAndCloseButton}>
                <button className={styles.saveButton} type='submit'>SAVE</button>
                <button className={styles.cancelButton} onClick={handleClose}>CANCEL</button>
              </div>

            </div>

            <div className='w-full'>
              <div className='relative'>
                <img className="w-full h-[12rem] object-cover object-center"
                  src={formik.values.backgroundImage != null ? formik.values.backgroundImage : 'https://cdn.pixabay.com/photo/2023/07/25/19/47/milky-way-8149815_640.jpg'} alt='' />

                <input
                  type='file'
                  className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                  onChange={handleImageChange} />
              </div>
            </div>

            <div className='w-full transform -translate-y-20 ml-4 h-[6rem]'>
              <div className='relative'>

                <img src={formik.values.image} className={styles.profileImage} />

                <input
                  className='absolute top-0 left-0 w-[10rem] h-full opacity-0 cursor-pointer'
                  onChange={onSelectProfilePhotoFile}
                  name='image'
                  type='file' />
              </div>
            </div>


            {profilePhotoError && <p style={{ color: 'red' }}>{profilePhotoError}</p>}
            {imgSrc &&

              <div className={styles.cropContainer}>
                <ReactCrop
                  crop={crop}
                  onChange={
                    (pixelCrop, percentCrop) => setCrop(percentCrop)
                  }
                  circularCrop
                  keepSelection
                  aspect={ASPECT_RATIO}
                  minWidth={MIN_DIMENSION}
                >
                  <img
                    ref={profilePhotoRef}
                    src={imgSrc} alt="Upload" style={{ width: '100%' }}
                    onLoad={onProfilePhotoLoad} />
                </ReactCrop>

                <button className={styles.cropImageButton}
                  onClick={() => {
                    setProfilePhotoCanvasPreview(
                      profilePhotoRef.current,
                      previewProfilePhotoCanvasRef.current,
                      convertToPixelCrop(
                        crop,
                        profilePhotoRef.current.width,
                        profilePhotoRef.current.height
                      )
                    );
                    const profilePhotoDataUrl = previewProfilePhotoCanvasRef.current.toDataURL()
                    formik.setFieldValue('image', profilePhotoDataUrl);
                    setImgSrc('');
                  }}>
                  Crop image
                </button>
              </div>

            }
            {crop && <canvas
              ref={previewProfilePhotoCanvasRef}
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: 150,
                height: 150
              }}
            />
            }


            <div className='space-y-6'>
              <TextField
                fullWidth
                id='name'
                name='name'
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onClick={handleNameFocus}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isNameFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                id='about'
                name='about'
                label="About"
                value={formik.values.about}
                onChange={formik.handleChange}
                onClick={handleAboutFocus}
                error={formik.touched.about && Boolean(formik.errors.about)}
                helperText={formik.touched.about && formik.errors.about}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isAboutFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              />
              <TextField
                fullWidth
                id='socialIdeology'
                name='socialIdeology'
                label="Social ideology"
                select
                value={formik.values.socialIdeology}
                onChange={formik.handleChange}
                onClick={handleSocialIdeologyFocus}
                error={formik.touched.socialIdeology && Boolean(formik.errors.socialIdeology)}
                helperText={formik.touched.socialIdeology && formik.errors.socialIdeology}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isSocialIdeologyFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              >
                <MenuItem value='CONSERVATISM'>Conservatism</MenuItem>
                <MenuItem value='SOCIAL_DEMOCRACY'>Social Democracy</MenuItem>
                <MenuItem value='LIBERALISM'>Liberalism</MenuItem>
                <MenuItem value='COMMUNISM'>Communism</MenuItem>
                <MenuItem value='ANARCHISM'>Anarchism</MenuItem>
                <MenuItem value='FASCISM'>Fascism</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id='socialIdeologyAttribute'
                name='socialIdeologyAttribute'
                label="Social ideology attribute"
                select
                value={formik.values.socialIdeologyAttribute}
                onChange={formik.handleChange}
                onClick={handleSocialIdeologyAttributeFocus}
                error={formik.touched.socialIdeologyAttribute && Boolean(formik.errors.socialIdeologyAttribute)}
                helperText={formik.touched.socialIdeologyAttribute && formik.errors.socialIdeologyAttribute}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isSocialIdeologyAttributeFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              >
                <MenuItem value='NATIONALISM'>Nationalism</MenuItem>
                <MenuItem value='REGIONALISM'>Regionalism</MenuItem>
                <MenuItem value='LIBERTARIANISM'>Libertarianism</MenuItem>
                <MenuItem value='MERITOCRACY'>Meritocracy</MenuItem>
                <MenuItem value='TECHNOCRACY'>Technocracy</MenuItem>
                <MenuItem value='ENVIRONMENTALISM'>Environmentalism</MenuItem>
                <MenuItem value='INTERNATIONALISM'>Internationalism</MenuItem>
                <MenuItem value='SYNDICALISM'>Syndicalism</MenuItem>
                <MenuItem value='FEMINISM'>Feminism</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id='economicIdeology'
                name='economicIdeology'
                label="Economic ideology"
                select
                value={formik.values.economicIdeology}
                onChange={formik.handleChange}
                onClick={handleEconomicIdeologyFocus}
                error={formik.touched.economicIdeology && Boolean(formik.errors.economicIdeology)}
                helperText={formik.touched.economicIdeology && formik.errors.economicIdeology}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isEconomicIdeologyFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              >
                <MenuItem value='LAISSEZ_FAIRE'>Laissez Faire</MenuItem>
                <MenuItem value='KEYNESIANISM'>Keynesianism</MenuItem>
                <MenuItem value='CORPORATISM'>Corporatism</MenuItem>
                <MenuItem value='PLANNED_ECONOMY'>Planned Economy</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id='formOfGovernmentIdeology'
                name='formOfGovernmentIdeology'
                label="Form of government preference"
                select
                value={formik.values.formOfGovernmentIdeology}
                onChange={formik.handleChange}
                onClick={handleFormOfGovernmentPreferenceFocus}
                error={formik.touched.formOfGovernmentIdeology && Boolean(formik.errors.formOfGovernmentIdeology)}
                helperText={formik.touched.formOfGovernmentIdeology && formik.errors.formOfGovernmentIdeology}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isFormOfGovernmentPreferenceFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              >
                <MenuItem value='MONARCHISM'>Monarchism</MenuItem>
                <MenuItem value='THEOCRACY'>Theocracy</MenuItem>
                <MenuItem value='REPUBLICANISM'>Republicanism</MenuItem>
                <MenuItem value='ONE_PARTY_SYSTEM'>One Party System</MenuItem>
                <MenuItem value='MILITARY_DICTATORSHIP'>Military dictatorship</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id='powerStructureIdeology'
                name='powerStructureIdeology'
                label="Power structure preference"
                select
                value={formik.values.powerStructureIdeology}
                onChange={formik.handleChange}
                onClick={handlePowerStructurePreferenceFocus}
                error={formik.touched.powerStructureIdeology && Boolean(formik.errors.powerStructureIdeology)}
                helperText={formik.touched.powerStructureIdeology && formik.errors.powerStructureIdeology}
                InputProps={{
                  style: {
                    backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                    border: isPowerStructurePreferenceFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                    color: 'white'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#71767b', // Reemplaza 'white' con el color deseado
                  },
                }}
              >
                <MenuItem value='UNITARY_STATE'>Unitary State</MenuItem>
                <MenuItem value='FEDERALISM'>Federalism</MenuItem>
              </TextField>

            </div>

          </form>
        </Box>
      </Modal>
    </div>
  );
}