import { api } from "../../../Config/api";


const socialApiEndpointBase = "/api/v2/posts";

export const getPlayerPosts = async (username, postsIndexToStartTheSearch, repostsIndexToStartTheSearch) => {
    try {
        const { data } = await api.get(`${socialApiEndpointBase}/profiles/${username}?postsIndexToStartTheSearch=${postsIndexToStartTheSearch}&repostsIndexToStartTheSearch=${repostsIndexToStartTheSearch}`);
        console.log("get player posts : ", data);
        return data;
    } catch (error) {
        console.log("error", error);
        throw error; // Propagate the error so it can be handled in the component
    }
};
