import axios from "axios"
import { API_BASE_URL, api, multipartApi, updateAuthorizationHeader } from "../../Config/api"
import { FOLLOW_UNFOLLOW_FAILURE, FOLLOW_UNFOLLOW_SUCCESS, GET_OWN_PLAYER_PROFILE_FAILURE, GET_OWN_PLAYER_PROFILE_SUCCESS, GET_PLAYER_PROFILE_FAILURE, GET_PLAYER_PROFILE_SUCCESS, LEVEL_UP_SKILL_SUCCESS, UPDATE_PROFILE_SUCCESS, WORK_DISCIPLINE_FAILURE, WORK_DISCIPLINE_SUCCESS } from "./ActionType"
import { JWT_REFRESH_PROFILE_UPDATE_SUCCESS } from "../Auth/ActionType"

// Si el Id del Params coincide con el guardado en el Estado, entonces se ejecuta este método, si no, se ejecuta el del PlayerProfile action
export const getPlayerProfile = (jwt)=>async(dispatch)=>{
    try{
        const {data}=await axios.get(`${API_BASE_URL}/api/player/profile`, {
            headers: {
                "Authorization":`Bearer ${jwt}`
            }
        })

        console.log(data);

        dispatch({type:GET_OWN_PLAYER_PROFILE_SUCCESS, payload:data})
    }catch(error){
        console.log("error", error)
        dispatch({type:GET_OWN_PLAYER_PROFILE_FAILURE, payload:error.message})
    }
}

// Si el ID del Params no coincide con el guardado en el Estado, entonces se ejecuta este método
export const getPlayerProfileByUsername = (username)=>async(dispatch)=>{
    try{
        const {data}=await api.get(`/api/player/profile/${username}`)

        dispatch({type:GET_PLAYER_PROFILE_SUCCESS, payload: data})

    }catch(error){
        console.log("error", error)
        dispatch({type:GET_PLAYER_PROFILE_FAILURE, payload:error.message})
    }
}

export const followUnfollowPlayer = (username)=>async(dispatch)=>{
    try{
        const {data}=await api.post(`/api/player/follow/${username}`
        )

        dispatch({type:FOLLOW_UNFOLLOW_SUCCESS, payload:data})
    }catch(error){
        console.log("error", error)
        dispatch({type:FOLLOW_UNFOLLOW_FAILURE, payload:error.message})
    }
}

export const workDiscipline = () => async(dispatch)=>{
    try{
        const {data} = await api.patch(`/api/player/discipline`);
        dispatch({type:WORK_DISCIPLINE_SUCCESS, payload:data})

    }catch (error) {

        console.log("error", error)
        dispatch({type:WORK_DISCIPLINE_FAILURE, payload: error.message})

    }
}

export const levelUpSkill = (skillType) => async(dispatch)=>{
    try{
        const {data} = await api.patch(`api/player/skills?skillType=${skillType}`);
        dispatch({type:LEVEL_UP_SKILL_SUCCESS, payload: {
            momentOfImprovement: data,
            improvingSkill: skillType
        }})
    }catch (error){
        console.log("error", error)

    }
}

export const updateProfile = (formData) => async(dispatch)=>{

    try{

        const {data} = await multipartApi.put(`api/player/profile`, formData);

        

        dispatch({type:UPDATE_PROFILE_SUCCESS, payload:data})
        dispatch({type:JWT_REFRESH_PROFILE_UPDATE_SUCCESS, payload: data})

    } catch (error){
        console.log("error", error);
    }
}