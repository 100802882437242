import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Avatar } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageIcon from '@mui/icons-material/Image';
import PollIcon from '@mui/icons-material/Poll';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, getFeed } from '../../../Store/Post/Action';
import CircularProgress from '../CircularProgress/CircularProgress';
import styles from './feed.module.css';
import { LimitPostsModal } from './Modals/LimitPostsModal';
import { ensureAuthorizationHeader } from '../../../Config/api';
import WorldOrderLogo from './WorldOrderLogo.png';
import { useScroll } from '../../../Utils/ScrollContextScrollProvider';
import { PostReplyMobileButton } from '../PostReplyMobileButton/PostReplyMobileButton';
import PublicationCard from '../PublicationCard/PublicationCard';
import CloseIcon from '../../Assets/CloseIcon.svg'

const validationSchema = Yup.object().shape({
    content: Yup.string().required('Post text is required').max(280, 'Cannot exceed 280 characters'),
    image: Yup.array().max(2, 'Cannot exceed 2 media items'),
    video: Yup.array().max(2, 'Cannot exceed 2 media items'),
});

const SocialSection = () => {

    const [charCount, setCharCount] = useState(0);
    const dispatch = useDispatch();
    const { post } = useSelector((store) => store);
    const { profileImageUrl } = useSelector(store => store.auth);
    const [openLimitPostsModal, setOpenLimitPostsModal] = useState({ open: false, isRequestAPost: false });
    const { isVisible } = useScroll();
    const [activeSection, setActiveSection] = useState('Posts');

    // Mantienen el estado de si se ve el input para poner la URL de imagen y de vídeo
    const [showImageInput, setShowImageInput] = useState(false);
    const [showVideoInput, setShowVideoInput] = useState(false);

    const handleOpenLimitPostsModal = useCallback((isPost) => setOpenLimitPostsModal({ open: true, isRequestAPost: isPost }), []);
    const handleCloseLimitPostsModal = useCallback(() => setOpenLimitPostsModal({ ...openLimitPostsModal, open: false }), [openLimitPostsModal]);

    const handleSubmit = useCallback((values, actions) => {
        dispatch(createPost(values, handleOpenLimitPostsModal));
        actions.resetForm();
        setCharCount(0);
    }, [handleOpenLimitPostsModal]);

    useEffect(() => {
        ensureAuthorizationHeader();
        dispatch(getFeed());
    }, [post.feedLike, post.feedRepost, post.feedReply]);

    const formik = useFormik({
        initialValues: {
            content: '',
            isPost: true,
            isReply: false,
            replyForId: null,
            image: [],
            video: [],
            imageUrl: '',
            videoUrl: '',
        },
        onSubmit: handleSubmit,
        validationSchema,
    });


    const handleInputChange = useCallback((e) => {
        const value = e.target.value;
        setCharCount(value.length);
        formik.handleChange(e);
    }, [formik]);

    const autoResize = useCallback((e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }, []);

    const progress = useMemo(() => (charCount / 280) * 100, [charCount]);

    const handleAddMedia = (type, url) => {
        if ((formik.values.image.length + formik.values.video.length) < 2) {
            formik.setFieldValue(type, [...formik.values[type], url]);
            formik.setFieldValue(`${type}Url`, '');
        }

        if (type === 'video') {
            setShowVideoInput(false)
        } else {
            setShowImageInput(false)
        }
    };

    const handleRemoveMedia = (index, type) => {
        const updatedMedia = [...formik.values[type]];
        updatedMedia.splice(index, 1);
        formik.setFieldValue(type, updatedMedia);
    };

    const renderMediaPreviews = () => {
        const allMedia = [
            ...formik.values.image.map(url => ({ url, type: 'image' })),
            ...formik.values.video.map(url => ({ url, type: 'video' }))
        ];

        return allMedia.map((media, index) => (
            <div key={index} className={styles.mediaPreview}>
                <div className={styles.mediaWrapper}>
                    {media.url.includes('youtube.com') ? (
                        <div className={styles.videoWrapper}>
                            <iframe
                                width="400"
                                height="225"
                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(media.url)}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                className={styles.responsiveIframe}
                            ></iframe>
                        </div>
                    ) : (
                        <img src={media.url} alt={`Media ${index + 1}`} className={styles.responsiveImage} />
                    )}
                    <button
                        className={styles.removeMediaButton}
                        onClick={() => handleRemoveMedia(index, media.type)}
                        type="button"
                    >
                        <img src={CloseIcon} className={styles.closeIcon} />
                    </button>
                </div>
            </div>
        ));
    };

    // Función auxiliar para extraer el ID del video de YouTube de la URL
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.header} ${isVisible ? styles.visible : styles.hidden}`}>
                <img src={WorldOrderLogo} alt="Logo" />
                <div className={styles.buttonContainer}>
                    <button
                        className={`${styles.button} ${activeSection === 'Posts' ? styles.active : ''}`}
                        onClick={() => setActiveSection('Posts')}
                    >
                        <p>For you</p>
                    </button>
                    <button
                        className={`${styles.button} ${activeSection === 'Articles' ? styles.active : ''}`}
                        onClick={() => setActiveSection('Articles')}
                    >
                        <p>Articles</p>
                    </button>
                </div>
            </div>

            <div className={styles.contentWrapper}>
                <div className={styles.emptyDiv}></div>
                <div className={styles.formContainer}>
                    <Avatar alt="username" src={profileImageUrl} />
                    <div className={styles.formContent}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <textarea
                                    type="text"
                                    name="content"
                                    placeholder="Tell your best lie..."
                                    className={styles.inputField}
                                    {...formik.getFieldProps('content')}
                                    onInput={(e) => {
                                        autoResize(e);
                                        handleInputChange(e);
                                    }}
                                    maxLength={280}
                                    rows={1}
                                />
                                {formik.errors.content && formik.touched.content && (
                                    <span className={styles.errorText}>{formik.errors.content}</span>
                                )}
                            </div>

                            <div className={styles.imageVideoContainer}>
                                {showImageInput && (
                                    <div className={styles.imageVideoInputContainer}>
                                        <input
                                            type="text"
                                            name="imageUrl"
                                            placeholder="Imgur image URL..."
                                            className={styles.imageVideoInputField}
                                            {...formik.getFieldProps('imageUrl')}
                                        />
                                        <button
                                            className={styles.imageVideoInputButton}
                                            type="button"
                                            onClick={() => handleAddMedia('image', formik.values.imageUrl)}
                                        >
                                            Add
                                        </button>
                                    </div>
                                )}
                                {showVideoInput && (
                                    <div className={styles.imageVideoInputContainer}>
                                        <input
                                            type="text"
                                            name="videoUrl"
                                            placeholder="YouTube video URL..."
                                            className={styles.imageVideoInputField}
                                            {...formik.getFieldProps('videoUrl')}
                                        />
                                        <button className={styles.imageVideoInputButton} type="button" onClick={() => handleAddMedia('video', formik.values.videoUrl)}>Add</button>
                                    </div>
                                )}
                                {(formik.errors.image || formik.errors.video) && (
                                    <span className={styles.errorText}>{formik.errors.image || formik.errors.video}</span>
                                )}
                            </div>

                            <div className={styles.mediaContent}>
                                {renderMediaPreviews()}
                            </div>

                            <div className={styles.formActions}>
                                <div className={styles.actionItems}>

                                    <ImageIcon
                                        className={styles.iconText}
                                        onClick={() => setShowImageInput(!showImageInput)} />
                                    <YouTubeIcon
                                        className={styles.iconText}
                                        onClick={() => setShowVideoInput(!showVideoInput)} />
                                    <PollIcon className={styles.iconText} />
                                    <TagFacesIcon className={styles.iconText} />
                                </div>
                                <div className={styles.circularProgressContainer}>
                                    <CircularProgress progress={progress} amountOfCharacters={charCount} />
                                </div>
                                <div>
                                    <button className={styles.socialPostButton} type="submit">
                                        <strong>Post</strong>
                                    </button>
                                    <button
                                        className={styles.mobileSocialPostButton}
                                        type="submit">
                                        <SendIcon className={styles.iconText} />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <section>{post.feed.map((item) => <PublicationCard item={item} key={item.id} openLimitPostsModal={handleOpenLimitPostsModal} isFeed={true} />)}</section>

                <section>
                    <LimitPostsModal open={openLimitPostsModal.open} handleClose={handleCloseLimitPostsModal} isPost={openLimitPostsModal.isRequestAPost} />
                </section>
            </div>

            <PostReplyMobileButton isPost={true} />
        </div>
    );
};

export default SocialSection;