import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { validateUserMail } from '../../Store/Auth/Action';

const ActivateAccountPage = ({navigate}) => {

    const [token, setToken] = useState('');
    const dispatch = useDispatch();

    const location = useLocation();
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const newToken = searchParams.get('token');
      if (newToken) {
        setToken(newToken);
      }
    }, [location]);
  
    useEffect(() => {
      if (token) {
        dispatch(validateUserMail(token, navigate));
      }
    }, [token]);

  return (
    <></>
  )
}

export default ActivateAccountPage