import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import VerifiedIcon from '@mui/icons-material/Verified';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import HomeFilled from './HomeIconFilled.png'
import HomeOutlined from './HomeIconOutlined.png'

export const navigationMenu=[
    {
        title:"Home",
        filledIcon:<img src={HomeFilled} style={{width:'23px'}}/>,
        outlinedIcon:<img src={HomeOutlined} style={{width:'23px'}}/>,
        path:"/social"
    },
    {
        title:"Worldview",
        filledIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,    
        outlinedIcon:<PublicIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/worldview"
    },
    {
        title:"Messages",
        filledIcon:<MessageIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<MessageOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/message"
    },
    {
        title:"Market",
        filledIcon:<ContactlessIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<ContactlessOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/market"
    },
    {
        title:"Office",
        filledIcon:<EmailIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<EmailOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/office"
    },
    ,
    {
        title:"Organizations",
        filledIcon:<AccountBalanceIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<AccountBalanceOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/organizations"
    },
    {
        title:"Conflict",
        filledIcon:<LocalFireDepartmentIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<LocalFireDepartmentOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/conflict"
    },
    {
        title:"Wiki",
        filledIcon:<InfoIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<InfoOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        url:"https://wiki.worldorder.app"
    },
    {
        title:"Premium",
        filledIcon:<VerifiedIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<VerifiedOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/premium"
    },
    {
        title:"Knowledge",
        filledIcon:<AutoAwesomeIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<AutoAwesomeOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/knowledge"
    },
    {
        title:"Profile",
        filledIcon:<PersonIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/profile/user"
    }
]