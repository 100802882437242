import axios from "axios"
import { jwtDecode } from "jwt-decode"

// export const API_BASE_URL = "http://localhost:8080"
export const API_BASE_URL = "https://api.worldorder.app"

export const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

// Para peticiones que envien contenido en form data, en vez de json (para enviar archivos, en vez de un JSON)
export const multipartApi = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "multipart/form-data"
    }
})

// Utilizamos esto para que, una vez logeado el user, todas las peticiones http que haga vayan autenticadas
export const updateAuthorizationHeader = (token) => {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
    multipartApi.defaults.headers["Authorization"] = `Bearer ${token}`;
}

// Nueva función para verificar y configurar el JWT si no está presente
export const ensureAuthorizationHeader = () => {
    const token = localStorage.getItem("jwt");

    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const expirationTime = new Date(decodedToken.exp * 1000);

            if (expirationTime > new Date()) {
                // El token aún es válido
                if (!api.defaults.headers["Authorization"]) {
                    updateAuthorizationHeader(token);
                }
            } else {
                // El token ha caducado, eliminarlo del localStorage
                localStorage.removeItem("jwt");
            }
        } catch (error) {
            console.error("Error al decodificar el token:", error);
            // Manejar el error de decodificación, por ejemplo, eliminando el token
            localStorage.removeItem("jwt");
        }
    }
};

