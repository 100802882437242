export function dataUrlToFile(dataurl, filename) {
  if (dataurl == null) {
      return null;
  }

  const arr = dataurl.split(",");
  if (arr.length < 2) {
      console.error("Invalid data URL");
      return null;
  }

  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch || mimeMatch.length < 2) {
      console.error("MIME type not found in data URL");
      return null;
  }

  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);
  for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mime });
}
