import { GET_USER_PROFILE_FAILURE, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, REGISTER_USER_FAILURE, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, LOGOUT, FOLLOW_USER_SUCCESS, UPDATE_USER_PROFILE_SUCCESS, RELOAD_PAGE_FAILURE, RELOAD_PAGE_SUCCESS, JWT_REFRESH_PROFILE_UPDATE_SUCCESS } from "./ActionType"
import { jwtDecode } from "jwt-decode";


const initialState = {
    name: null,
    username: null,
    profileImageUrl: null,
    playerId: null,
    loading: false,
    error: null,
    JWT: null,
}

export const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_USER_REQUEST:
        case REGISTER_USER_REQUEST:
        case GET_USER_PROFILE_REQUEST:
            return { ...state, loading: true, error: null }

        case RELOAD_PAGE_SUCCESS:

            const decoded_token = jwtDecode(action.payload)

            return {
                ...state,
                loading: false,
                error: null,
                JWT: action.payload,
                name: decoded_token.name,
                username: decoded_token.username,
                profileImageUrl: decoded_token.image,
                playerId: decoded_token.id
            }

        case JWT_REFRESH_PROFILE_UPDATE_SUCCESS:

            const jwtRefreshProfileUpdateDecodedToken = jwtDecode(action.payload.JWT)

            return { 
                ...state, 
                loading: false,
                error: null,
                JWT: action.payload.JWT, 
                name: jwtRefreshProfileUpdateDecodedToken.name, 
                username: jwtRefreshProfileUpdateDecodedToken.username, 
                playerId: jwtRefreshProfileUpdateDecodedToken.id, 
                profileImageUrl: jwtRefreshProfileUpdateDecodedToken.image 
            };

        case LOGIN_USER_SUCCESS:
            // Decodificamos el token JWT para obtener los datos
            const decodedToken = jwtDecode(action.payload)

            return { ...state, loading: false, error: null, JWT: action.payload, name: decodedToken.name, username: decodedToken.username, playerId: decodedToken.id, profileImageUrl: decodedToken.image };

        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, error: null }

        case GET_USER_PROFILE_SUCCESS:
            // Cargamos en el state los datos del perfil del player, para que cuando acceda a Profile pueda ver el perfil completo
            return { ...state, loading: false, error: null, name: action.payload.targetUser.name }

        case RELOAD_PAGE_FAILURE:
        case LOGIN_USER_FAILURE:
        case REGISTER_USER_FAILURE:
        case GET_USER_PROFILE_FAILURE:
            return { ...state, loading: false, error: action.payload }

        case FOLLOW_USER_SUCCESS:
            return { ...state, loading: false, error: null, user: action.payload, updateUser: true }

        case UPDATE_USER_PROFILE_SUCCESS:
            return

        case LOGOUT:
            return initialState

        default:
            return state;
    }
}