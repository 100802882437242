import React from 'react'

export const CommentIcon = ({ fillColor = '#71767B' }) => {
    return (
        <svg width="16.8" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58 3.584 0 8.005 0H12.371C16.861 0 20.5 3.64 20.5 8.13C20.5 11.09 18.893 13.81 16.304 15.24L8.25 19.7V16.01H8.183C3.693 16.11 0 12.5 0 8ZM8.005 2C4.688 2 2 4.69 2 8C2 11.37 4.77 14.08 8.138 14.01L8.489 14H10.25V16.3L15.337 13.49C17.288 12.41 18.5 10.36 18.5 8.13C18.5 4.74 15.756 2 12.371 2H8.005Z"
                fill={fillColor}
            />
        </svg>

    )
}
