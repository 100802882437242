import React, { useEffect } from 'react';
import styles from './PrivacyPolicy.module.css';

export const PrivacyPolicy = () => {

  useEffect(() => {
    const headerHeight = 120; // Ajusta este valor según la altura real de tu header

    const handleAnchorClick = (event) => {
      const targetId = event.currentTarget.getAttribute('href').slice(1);
      const element = document.getElementById(targetId);
      if (element) {
        event.preventDefault(); // Previene el comportamiento predeterminado del ancla
        const offsetPosition = element.offsetTop - headerHeight;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
        // Cambia el hash en la URL sin recargar la página
        window.history.pushState(null, null, `#${targetId}`);
      }
    };

    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(anchor => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    return () => {
      anchors.forEach(anchor => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);



  return (
    <div className={styles.privacyContainer}>

      <div className={styles.titleContainer}>
        <p>WO Privacy Policy</p>
      </div>

      <div className={styles.effectiveContainer}>
        <p>Effective: August 25, 2024</p>
      </div>

      <div className={styles.privacyPolicyContent}>

        <div className={styles.sectionLinks}>

          <ul>
            <li><strong>Privacy Policy</strong></li>
            <li><a href="#wo-privacy-1">1. Information We Collect</a></li>
            <li><a href="#wo-privacy-2">2. How We Use Information</a></li>
            <li><a href="#wo-privacy-3">3. Sharing Information</a></li>
            <li><a href="#wo-privacy-4">4. How Long We Keep Information</a></li>
            <li><a href="#wo-privacy-5">5. Take Control</a></li>
            <li><a href="#wo-privacy-6">6. Your Rights and Ours</a></li>
            <li><a href="#wo-privacy-7">7. WO's Audience</a></li>
            <li><a href="#wo-privacy-8">8. Changes To This Privacy Policy</a></li>
            <li><a href="#wo-privacy-9">9. General</a></li>
            <li><a href="#wo-privacy-10">10. How To Contact WO</a></li>
          </ul>

        </div>



        <div className={styles.sections}>

          {/* Secciones de la política de privacidad */}
          <p id="wo-privacy-1" className={styles.sectionTitle}>1. Information We Collect</p>
          <p>The information we collect when you use WO falls into three categories.</p>
          <p><strong>1.1 Information you provide us.</strong></p>
          <p>To use some of our products and services you need to have an account, and to create an account, you need to provide us certain information. Likewise, if you use our paid products and services, we cannot provide them to you without getting payment information. Basically, certain information is necessary if you want to use many of our products and services.</p>
          <ul>
            <li><strong>Personal Accounts.</strong> If you create an account, you must provide us with some information so that we can provide our services to you. This includes a display name (for example, “User); a username (for example, @username); a password; an email address or phone number; a date of birth; your display language; and third-party single sign-in information (if you choose this sign-in method). Your profile information, which includes your display name and username, is always public, but you can use either your real name or a pseudonym. And remember, you can create two WO accounts per IP address, for example, to express different parts of your identity or try out different game mechanics. </li>
            <li><strong>Payment Information.</strong> In order to purchase ads or other offerings provided as part of our paid products and services you will need to provide us payment information, including your credit or debit card number, card expiration date, CVV code, billing address and a contact email.</li>
            <li><strong>Preferences.</strong> When you set your preferences using your settings, we collect that information so that we can respect your preferences.</li>
            <li><strong>Biometric Information.</strong> Based on your consent, we may collect and use your biometric information for safety, security, and identification purposes.</li>
          </ul>
          <p><strong>1.2 Information we collect when you use World Order.</strong></p>
          <p>When you use our services, we collect information about how you use our products and services. We use that information to provide you with products and services, to help keep World Order more secure and respectful for everyone, and more relevant to you.</p>
          <p><strong>Usage Information. </strong> We collect information about your activity on WO, including:</p>
          <ul>
            <li>Posts and other content you post (including the date, application, and version of World Order)</li>
            <li>Your interactions with other users’ content, such as reposts, likes, bookmarks, shares, replies, if other users mention or tag you in content or if you mention or tag them.</li>
            <li>How you interact with others on the platform, such as people you follow and people who follow you, metadata related to Encrypted Messages, and when you use Direct Messages, including the contents of the messages, the recipients, and date and time of messages.</li>
            <li>If you communicate with us, such as through email, we will collect information about the communication and its content.</li>
            <li>We collect information on links you interact with across our services (including in our emails sent to you).</li>
          </ul>
          <p><strong>Purchase and payments.</strong> To allow you to make a payment or send money using WO features or services, including through an intermediary, we may receive information about your transaction such as when it was made, when a subscription is set to expire or auto-renew, and amounts paid or received.</p>
          <p><strong>Device Information.</strong> We collect information from and about the devices you use to access WO, including:</p>
          <ul>
            <li>Information about your connection, such as your IP address, browser type, and related information.</li>
            <li>Information about your device and its settings, such as device and advertising ID, operating system, carrier, language, memory, apps installed, and battery level.</li>
            <li>Your device address book, if you’ve chosen to share it with us.</li>
          </ul>
          <p><strong>Location Information. </strong> When you use World Order, we collect some information about your approximate location to provide the service you expect, including showing you relevant ads. You can also choose to share your current precise location or places where you’ve previously used WO by enabling these settings in your account.</p>
          <p><strong>Inferred Identity.</strong> We may collect or receive information that we use to infer your identity as detailed below:</p>
          <ul>
            <li>When you sign into WO on a browser or device, we will associate that browser or device with your account. Subject to your settings, we may also associate your account with browsers or devices other than those you use to sign into WO (or associate your signed-out device or browser with other browsers or devices or WO-generated identiﬁers).</li>
            <li>When you provide other information to WO, including an email address or phone number, we associate that information with your WO account. Subject to your settings, we may also use this information in order to infer other information about your identity, for example by associating your account with hashes of email addresses that share common components with the email address you have provided to World Order.</li>
            <li>When you access WO and are not signed in, we may infer your identity based on the information we collect.</li>
          </ul>
          <p><strong>Log Information</strong> We may receive information when you view content on or otherwise interact with our products and services, even if you have not created an account or are signed out, such as:</p>
          <ul>
            <li>IP address and related information; browser type and language; operating system; the referring webpage; access times; pages visited; location; your mobile carrier; device information (including device and application IDs); search terms and IDs (including those not submitted as queries); ads shown to you on WO; WO-generated identiﬁers; and identiﬁers associated with cookies. We also receive log information when you click on, view, or interact with links on our services, including when you install another application through WO.</li>
          </ul>
          <p><strong>Advertisements.</strong> When you view or interact with ads we serve on or oﬀ WO, we may collect information about those views or interactions (e.g., watching a video ad or preroll, clicking on an ad, interacting with reposts of or replies to an ad).</p>
          <p><strong>Cookies and similar technologies.</strong> Like many websites, we use cookies and similar technologies to collect additional website usage data and to operate our services. Cookies are not required for many parts of our products and services such as searching and looking at public proﬁles. You can learn more about how we use cookies and similar technologies <a href='https://en.wikipedia.org/wiki/HTTP_cookie'>here</a>.</p>
          <p><strong>1.3 Information we receive from third parties.</strong></p>
          <p>When you use other online products and services, they may share information about that usage with us.</p>
          <p><strong>Ad Partners, Developers, Publishers.</strong> Our ad and business partners share information with us such as browser cookie IDs, WO-generated identifiers, mobile device IDs, hashed user information like email addresses, demographic or interest data, and content viewed or actions taken on a website or app. Some of our ad partners, particularly our advertisers, also enable us to collect similar information directly from their website or app by integrating our advertising technology. Information shared by ad partners and affiliates or collected by WO from the websites and apps of ad partners and affiliates may be combined with the other information you share with WO and that WO receives, generates, or infers about you described elsewhere in this Privacy Policy.</p>
          <p><strong>Other Third Parties, Account Connections, and Integrations.</strong> We may receive information about you from third parties who are not our ad partners, such as other World Order users, developers, and partners who help us evaluate the safety and quality of content on our platform, our corporate affiliates, and other services you link to your WO account. You may choose to connect your WO account to your account on another service, and that other service may send us information about your account on that service.</p>
          <hr />

          <p id="wo-privacy-2" className={styles.sectionTitle}>2. How We Use Information</p>
          <p>Breaking down how we use the information we collect is not simple because of the way the systems that bring our services to you work. For example, the same piece of information may be used differently for different purposes to ultimately deliver a single service. We think it’s most useful to describe the five main ways we use information and if you have questions that are not answered, you can always contact us. Here we go:</p>
          <p><strong>2.1 Operate, improve, and personalize our services.</strong></p>
          <p>We use the information we collect to provide and operate WO products and services. We also use the information we collect to improve and personalize our products and services so that you have a better experience on World Order, including by showing you more relevant content and ads, suggesting people and topics to follow, enabling and helping you discover affiliates, third-party apps, and services. We may use the information we collect and publicly available information to help train our machine learning or artificial intelligence models for the purposes outlined in this policy.</p>
          <p>We may use the information we collect from accounts of other services that you choose to connect your WO account to provide you features like cross-posting or cross-service authentication, and to operate our services.</p>
          <p>We use your contact information to help others find your account if your settings permit, including through third-party services and client applications.</p>
          <p>We use your information to provide our advertising and sponsored content services subject to your settings, which helps make ads on WO more relevant to you. We also use this information to measure the effectiveness of ads and to help recognize your devices to serve you ads on and off of WO.</p>
          <p><strong>2.2  Foster safety and security.</strong></p>
          <p>We use information we collect to provide for the safety and security of our users, our products, services, and your account. This includes verifying your identity, authenticating your account, and defending against fraud, unauthorized use, and illegal activity. We also use the information to evaluate and affect the safety and quality of content on WO - this includes investigating and enforcing our policies and and terms, as well as applicable law.</p>
          <p><strong>2.3 Measure, analyze and make our services better.</strong></p>
          <p>We use the information we collect to measure and analyze the effectiveness of our products and services and to better understand how you use them in order to make them better.</p>
          <p><strong>2.4 Communicate with you about our services.</strong></p>
          <p>We use the information we collect to communicate with you about our products and services, including about product updates and changes to our policies and terms. If you’re open to hearing from us, we may also send you marketing messages from time to time.</p>
          <p><strong>2.5 Research.</strong></p>
          <p>We use information you share with us, or that we collect to conduct research, surveys, product testing, and troubleshooting to help us operate and improve our products and services.</p>
          <hr />



          <p id="wo-privacy-3" className={styles.sectionTitle}>3. Sharing Information</p>
          <p>You should know the ways we share your information, why we share it, and how you can control it. There are five general ways we share your information.</p>
          <p><strong>3.1 When you post and share.</strong></p>
          <p><strong>With the general public.</strong> You are directing us to disclose that information as broadly as possible. WO content, including your profile information (e.g., name/pseudonym, username, profile pictures), is available for viewing by the general public. The public does not need to be signed in to view some content on WO. They may also find WO content off of World Order, for example from search query results on Internet search engines.</p>
          <p><strong>With other World Order users.</strong> Depending on your settings, and based on the WO products and services you use, we share:</p>
          <ul>
            <li>Your interactions with WO content of other users, such as likes, and people you follow.</li>
            <li>Content you send to a specific WO user, such as through Direct Messages. Please keep in mind that if you’ve shared information like Direct Messages or protected posts with someone else who accesses WO through a third-party service, the information may be shared with the third-party service.</li>
          </ul>
          <p><strong>With partners.</strong> Depending on your settings, we also provide certain third parties with information to help us offer or operate our products and services. You can control whether World Order shares your personal information with these partners by using the “Data sharing with business partners” option in your Privacy & Safety settings. (This setting does not control sharing described elsewhere in this Privacy Policy, such as when we share information with our service providers.)</p>
          <p><strong>3.2 With third parties & third-party integrations.</strong></p>
          <p><strong>With service providers.</strong> We may share your information with our service providers that perform functions and provide services on our behalf, including payment services providers who facilitate payments; service providers that host our various blogs and wikis; service providers that help us understand the use of our services; and those that provide fraud detection services. </p>
          <p><strong>With advertisers.</strong> Advertising revenue enables us to provide our products and services. Advertisers may learn information from your engagement with their ads on or off WO. For example, if you click on an external link or ad on our services, that advertiser or website operator might figure out that you came from WO, along with other information associated with the ad you clicked, such as characteristics of the audience it was intended to reach and other WO-generated identifiers for that ad. They may also collect other personal information from you, such as cookie identifiers, or your IP address.</p>
          <p><strong>Third-party content & integrations.</strong> We share or disclose your information with your consent or at your direction, such as when you authorize a third-party web client or application to access your account or when you direct us to share your feedback with a business. Similarly, to improve your experience, we work with third-party partners to display their video content on WO or to allow cross-platform sharing. When you watch or otherwise interact with content from our video or cross-platform sharing partners, they may receive and process your personal information as described in their privacy policies. For video content, you can adjust your autoplay settings if you prefer that content not to play automatically.</p>
          <p><strong>Through our APIs.</strong> We use technology like APIs and embeds to make public WO information available to websites, apps, and others for their use, for example, displaying posts on a news website or analyzing what people say on WO. We generally make this content available in limited quantities for free and charge licensing fees for large-scale access. We have standard terms that govern how this information can be used, and a compliance program to enforce these terms. But these individuals and companies are not affiliated with WO, and their offerings may not reflect updates you make on WO.</p>
          <p><strong>3.3 When required by law, to prevent harm, or in the public interest.</strong></p>
          <p>We may preserve, use, share, or disclose your information if we believe that it is reasonably necessary to:</p>
          <ul>
            <li>comply with a law, regulation, legal process, or governmental request;</li>
            <li>protect the safety of any person, protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our services;</li>
            <li>explain why we have removed content or accounts from our services (e.g., for a violation of Our Rules);</li>
            <li>address fraud, security, or technical issues; or</li>
            <li>protect our rights or property, or the rights or property of those who use our services.</li>
          </ul>
          <p><strong>3.4 With our Affiliates.</strong></p>
          <p>We may share information amongst our affiliates to provide our products and services.</p>
          <p><strong>3.5 As a result of a change in ownership.</strong></p>
          <p>We may share, sell, or transfer information about you in connection with a merger, acquisition, reorganization, sale of assets, or bankruptcy. This Privacy Policy will apply to your personal information that is shared with (before and after the close of any transaction) or transferred to the new entity.</p>
          <hr />



          <p id="wo-privacy-4" className={styles.sectionTitle}>4. How Long We Keep Information</p>
          <p>We keep different types of information for different periods of time: </p>
          <ul>
            <li>We keep your profile information and content for the duration of your account.</li>
            <li>We generally keep other personally identifiable data we collect when you use our products and services for a maximum of 18 months.</li>
            <li>Remember public content can exist elsewhere even after you remove it from WO. For example, search engines and other third parties may retain copies of your posts longer, based upon their own privacy policies, even after they are deleted or expire on WO. You can read more about search visibility here.</li>
            <li>Where you violate our Rules and your account is suspended, we may keep the identifiers you used to create the account (i.e., email address or phone number) indefinitely to prevent repeat policy offenders from creating new accounts.</li>
            <li>We may keep certain information longer than our policies specify in order to comply with legal requirements and for safety and security reasons.</li>
          </ul>
          <hr />



          <p id="wo-privacy-5" className={styles.sectionTitle}>5. Take Control</p>
          <p><strong>5.1 Access, Correction, Portability.</strong></p>
          <p>You can access, correct, or modify the information you provided to us by editing your profile and adjusting your account settings.</p>
          <ul>
            <li>You can learn more about the information we have collected or inferred about you in Your WO Data.</li>
            <li>You can download a copy of your information, such as your posts, by following the instructions here.</li>
          </ul>
          <p>To protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal information or complying with a deletion, portability, or other related request. We may, in certain situations, reject your request for access, correction, or portability, for example, we may reject access where you are unable to verify your identity.</p>
          <p><strong>5.2 Deleting your Information.</strong></p>
          <p>If you follow the instructions here, your account will be deactivated and your data will be queued for deletion. When deactivated, your WO account, including your display name, username, and public profile, will no longer be viewable on worldorder.app, World Order - Geopolitical Simulator for iOS, and World Order - Geopolitical Simulator for Android. For up to 30 days after deactivation it is still possible to restore your WO account if it was accidentally or wrongfully deactivated.</p>
          <p><strong>5.3 Objecting to, Restricting, or Withdrawing your Consent.</strong></p>
          <p>You can manage your privacy settings and other account features here. If you change your settings it may take some time for your choices to be fully reflected throughout our systems. You may also notice changes in your WO experience or limitations in your ability to access certain features depending on the settings you’ve adjusted. You may also manage additional settings when interacting with certain content and features on different parts of the platform, such as whether videos you upload are downloadable by others.</p>
          <p><strong>5.4 Authorized Agent Requests.</strong></p>
          <p>To submit a request related to access, modification, or deletion of your information, or someone else’s information if you are their authorized agent, you may also contact us as specified in the How To Contact WO section of our Privacy Policy below. We may require you to provide additional information for verification.</p>
          <hr/>



          <p id="wo-privacy-6" className={styles.sectionTitle}>6. Your Rights and Ours</p>
          <p>We provide World Order to people all over the world and provide many of the same privacy tools and controls to all of our users regardless of where they live. However, your experience may be slightly different than users in other countries to ensure WO respects local requirements.</p>
          <p><strong>6.1 We have specific legal bases to use your information.</strong></p>
          <p>WO has carefully considered the legal reasons it is permitted to collect, use, share and otherwise process your information. If you want to dig in to learn more and better understand the nuances, we’d encourage you to check out this additional information about data processing. And no, we don’t sell your personal information. </p>
          <p><strong>6.2 We move your data to make WO work for you.</strong></p>
          <p>Just as you use WO to seamlessly play your role in the global simulator and participate in conversations with people in countries all over the world, WO must move information across borders and to different countries around the world to support the safe and reliable service you depend on. For example, if you live in the United States and are having a conversation with someone in the United Kingdom, information has to move between those countries to provide that experience – it’s what you expect from us.</p>
          <p>We also use data centers and cloud providers, and engage our affiliates and third-party partners and service providers located in many parts of the world to help us provide our services. Before we move data between countries we look at the risks that may be presented to the data and rely on standard contractual clauses (SCCs), where applicable, to ensure your data rights are protected. To request a copy of the SCCs, please contact us here. If data will be shared with a third party, we require them to maintain the same protections over your data that we provide directly.</p>
          <hr/>



          <p id="wo-privacy-7" className={styles.sectionTitle}>7. WO's Audience</p>
          <p>Our services are not directed to children, and you may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf).</p>
          <hr/>

          <p id="wo-privacy-8" className={styles.sectionTitle}>8. Changes To This Privacy Policy</p>
          <p>The most current version of this Privacy Policy governs our processing of your personal data and we may revise this Privacy Policy from time to time as needed.</p>
          <p>If we do revise this Privacy Policy and make changes that are determined by us to be material, we will provide you notice and an opportunity to review the revised Privacy Policy before you continue to use WO.</p>
          <hr/>

          <p id="wo-privacy-9" className={styles.sectionTitle}>9. General</p>
          <p>The WO Privacy Policy is written in English but is made available in multiple languages through translations. World Order strives to make the translations as accurate as possible to the original English version. However, in case of any discrepancies or inconsistencies, the English language version of the WO Privacy Policy shall take precedence. You acknowledge that English shall be the language of reference for interpreting and constructing the terms of the WO Privacy Policy. </p>

          <p id="wo-privacy-10" className={styles.sectionTitle}>10. How To Contact WO</p>
          <p>We want to hear from you if you have thoughts or questions about this Privacy Policy. You can contact us via our Privacy Policy Inquiries page or by writing to us at the appropriate address below.</p>



        </div>



      </div>
    </div>
  )
}
