import * as React from 'react';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { api } from '../../Config/api';

import styles from './premiummodal.module.css';


export default function PremiumModal({ handleClose, open }) {

    const [selectedPlan, setSelectedPlan] = React.useState('Golden');

    const fetchGreenCheckoutSessionUrl = async () => {
        // Acción para el plan Green
        const response = await api.post(`/api/premium/green-create-checkout-session`);
        window.open(response.data, '_blank');
        console.log(response.data);
    }

    const fetchGoldenCheckoutSessionUrl = async () => {
        // Acción para el plan Golden
        const response = await api.post(`/api/premium/golden-create-checkout-session`);
        window.open(response.data, '_blank');
    }

    const pay = (plan) => {
        
        if (plan !== undefined) {

            if (plan === 'Green') {
                fetchGreenCheckoutSessionUrl();
            } else {
                fetchGoldenCheckoutSessionUrl();
            }
        } else if (selectedPlan === 'Green') {
            fetchGreenCheckoutSessionUrl();

        } else {
            fetchGoldenCheckoutSessionUrl();
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className={styles.modalBox}>
                    <div className={styles.content}>

                        <div className={styles.closeIconContainer}>
                            <IconButton onClick={handleClose} aria-label='delete'>
                                <CloseIcon className={styles.closeIcon} />
                            </IconButton>
                        </div>

                        <h1 className={styles.modalTitle}>Upgrade to Premium</h1>
                        <h3 className={styles.modalSubtitle}>Enjoy an enhanced experience, exclusive benefits, top-tier verification and security.</h3>

                        <div className={styles.plansContainer}>
                            <div className={`${styles.premiumPlan} ${selectedPlan == 'Green' ? styles.selectedPremiumPlan : ''}`} onClick={() => setSelectedPlan('Green')}>
                                <p className={styles.title}>Green</p>
                                <p className={styles.monthlyPrice}>€2.49 <span style={{ fontSize: '16px', fontWeight: 'normal' }}>/ month</span></p>
                                <p className={styles.billedAnually}>€29.99 billed anually</p>
                                <button onClick={() => pay('Green')}>Subscribe</button>
                                <ul>
                                    <li>Upload images</li>
                                    <li>2 working slots</li>
                                    <li>Up to 10 bank accounts</li>
                                    <li>x1.5 experience</li>
                                    <li>¾ time learning</li>
                                    <li>Up to 5 posts per day</li>
                                    <li>Up to 10 responses per day</li>
                                </ul>
                            </div>
                            <div className={`${styles.premiumPlan} ${selectedPlan == 'Golden' ? styles.selectedPremiumPlan : ''}`} onClick={() => setSelectedPlan('Golden')} >
                                <p className={styles.title}>Golden</p>
                                <p className={styles.monthlyPrice}>€3.33 <span style={{ fontSize: '16px', fontWeight: 'normal' }}>/ month</span></p>
                                <p className={styles.billedAnually}>€39.99 billed anually</p>
                                <button onClick={() => pay('Golden')}>Subscribe</button>
                                <ul>
                                    <li>Upload images & videos</li>
                                    <li>3 working slots</li>
                                    <li>Up to 15 bank accounts</li>
                                    <li>x2 experience</li>
                                    <li>½ time learning</li>
                                    <li>Up to 10 posts per day</li>
                                    <li>Up to 15 responses per day</li>
                                    <li>Premium knowledge</li>
                                    <li>World Order Club</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className={styles.footer}>

                        <div className={styles.premiumTypeAndPrice}>
                            <p className={styles.premiumType}>{selectedPlan}</p>
                            <p className={styles.price}>{selectedPlan == 'Golden' ? '€39.99' : '€29.99'} / year</p>
                            <p>Billed anually</p>
                        </div>

                        <div className={styles.subscribeAndTermsContainer}>

                            <button onClick={() => pay()}>Subscribe & Pay</button>

                            <p className={styles.termsText}>
                                By subscribing, you agree to our Purchaser Terms of Service. Subscriptions auto-renew until canceled, as described in the Terms. Cancel anytime.
                            </p>


                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    );
}
