import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import VerifiedIcon from '@mui/icons-material/Verified';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

export const moreNavigationMenu=[
    {
        title:"Messages",
        filledIcon:<MessageIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<MessageOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/message"
    },
    {
        title:"Market",
        filledIcon:<ContactlessIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<ContactlessOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/market"
    },
    {
        title:"Organizations",
        filledIcon:<AccountBalanceIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<AccountBalanceOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/organizations"
    },
    {
        title:"Conflict",
        filledIcon:<LocalFireDepartmentIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<LocalFireDepartmentOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/conflict"
    },
    {
        title:"Wiki",
        filledIcon:<InfoIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<InfoOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        url:"https://wiki.worldorder.app"
    },
    {
        title:"Premium",
        filledIcon:<VerifiedIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<VerifiedOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/premium"
    },
    {
        title:"Knowledge",
        filledIcon:<AutoAwesomeIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<AutoAwesomeOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/knowledge"
    },
]