import React from 'react';

// Para formatear las fechas con horas

export const FormattedDate = ({ date }) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    });

    return <p>{formattedDate}</p>;
};