import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./Auth/Reducer";
import { postReducer } from "./Post/Reducer";
import { playerProfileReducer } from "./PlayerProfile/Reducer";
import { mapReducer } from "./Map/Reducer";

const rootReducers = combineReducers({

    auth:authReducer, 
    post:postReducer,
    playerProfile:playerProfileReducer,
    worldview:mapReducer

});

export const store = legacy_createStore(rootReducers, applyMiddleware(thunk));