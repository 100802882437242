import { IconButton, Modal } from '@mui/material'
import styles from './limitPostsModal.module.css'
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

export const LimitPostsModal = ({ open, handleClose, isPost }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } } }}
        >
            <div className={styles.modalContainer}>
                <div className={styles.modalStyle}>

                    <div className={styles.modalContent}>

                        <div className={styles.header}>
                            <div className={styles.headerLeft}>
                                <p className={styles.headerText}><strong>Limit reached</strong></p>
                            </div>
                            <IconButton onClick={handleClose} aria-label='delete'>
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </div>

                        {isPost
                            ? 
                            <>
                                <p>You have reached the limit of daily posts.</p>
                                <ul className={styles.premiumLimitList}>
                                    <li>Gold Premium: 6 daily posts</li>
                                    <li>Green Premium: 3 daily posts</li>
                                    <li>No premium: 1 daily posts</li>
                                </ul>
                            </>
                            : <>
                            <p>You have reached the limit of daily responses.</p>
                            <ul className={styles.premiumLimitList}>
                                    <li>Gold Premium: 15 daily replies</li>
                                    <li>Green Premium: 7 daily replies</li>
                                    <li>No premium: 3 daily replies</li>
                                </ul>
                            </>
                        }



                    </div>
                </div>
            </div>
        </Modal >
    )
}
