export const GET_PLAYER_PROFILE_REQUEST="GET_PLAYER_PROFILE_REQUEST"
export const GET_PLAYER_PROFILE_SUCCESS="GET_PLAYER_PROFILE_SUCCESS"
export const GET_PLAYER_PROFILE_FAILURE="GET_PLAYER_PROFILE_FAILURE"

export const GET_OWN_PLAYER_PROFILE_REQUEST="GET_OWN_PLAYER_PROFILE_REQUEST"
export const GET_OWN_PLAYER_PROFILE_SUCCESS="GET_OWN_PLAYER_PROFILE_REQUEST"
export const GET_OWN_PLAYER_PROFILE_FAILURE="GET_OWN_PLAYER_PROFILE_REQUEST"

export const FOLLOW_UNFOLLOW_REQUEST="FOLLOW_UNFOLLOW_REQUEST"
export const FOLLOW_UNFOLLOW_SUCCESS="FOLLOW_UNFOLLOW_SUCCESS"
export const FOLLOW_UNFOLLOW_FAILURE="FOLLOW_UNFOLLOW_FAILURE"

export const WORK_DISCIPLINE_REQUEST="WORK_DISCIPLINE_REQUEST"
export const WORK_DISCIPLINE_SUCCESS="WORK_DISCIPLINE_SUCCESS"
export const WORK_DISCIPLINE_FAILURE="WORK_DISCIPLINE_FAILURE"

export const LEVEL_UP_SKILL_REQUEST="LEVEL_UP_SKILL_REQUEST"
export const LEVEL_UP_SKILL_SUCCESS="LEVEL_UP_SKILL_SUCCESS"
export const LEVEL_UP_SKILL_FAILURE="LEVEL_UP_SKILL_FAILURE"

export const UPDATE_PROFILE_REQUEST="UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS="UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAILURE="UPDATE_PROFILE_FAILURE"

