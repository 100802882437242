import React, { useState } from 'react';
import styles from './tabs.module.css';

export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {children.map((child) => (
          <div
            key={child.props.label}
            className={`${styles.tab} ${activeTab === child.props.label ? styles.active : ''}`}
            onClick={() => handleTabClick(child.props.label)}
          >
            {child.props.label}
          </div>
        ))}
      </div>

      <div>
        {children.map((child) => {
          if (child.props.label !== activeTab) return null;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export const Tab = ({ children }) => {
  return <div>{children}</div>;
};
