
import { CLEAN_PLAYER_PROFILE_POSTS_FAILURE, CLEAN_PLAYER_PROFILE_POSTS_REQUEST, CLEAN_PLAYER_PROFILE_POSTS_SUCCESS, GET_FEED_SUCCESS, GET_PLAYER_PROFILE_POSTS_SUCCESS, GET_POST_RESPONSES_FAILURE, GET_POST_RESPONSES_REQUEST, GET_POST_RESPONSES_SUCCESS, POST_CREATE_FAILURE, POST_CREATE_REQUEST, POST_CREATE_SUCCESS, POST_DELETE_FAILURE, POST_DELETE_REQUEST, POST_DELETE_SUCCESS, REPLY_POST_SUCCESS, REPOST_FAILURE, REPOST_REQUEST, REPOST_SUCCESS, USER_LIKE_POST_FAILURE, USER_LIKE_POST_REQUEST, USER_LIKE_POST_SUCCESS } from "./ActionType"

const initialState = {
    loading: false,
    data: null,
    error: null,
    posts: [],
    // feed es el conjunto de posts del feed del usuario, y se carga cuando el usuario accede al apartado Social
    feed: [],
    feedLike: [],
    feedRepost: [],
    feedReply: [],
    // postDetails es el Post al que se ha clicado para acceder a ver sus respuestas
    postDetails: null,
    // postReplies es el conjunto de respuestas del postDetails actual
    postReplies: [],


    // profilePosts es el conjunto de posts del apartado de POSTS en el perfil de un player
    profilePosts:[],

    // Páginas e índices para hacer búsquedas en el feed del usuario, para hacer más consultas de posts del feed en caso de ser necesario
    pageAndIndexForFeed: {
        pageForPosts: 0,
        postsIndexToStartTheSearch : 0,
        pageForReposts : 0,
        repostsIndexToStartTheSearch: 0
    }

}

export const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_CREATE_REQUEST:
        case POST_DELETE_REQUEST:
        case USER_LIKE_POST_REQUEST:
        case REPOST_REQUEST:
        case GET_POST_RESPONSES_REQUEST:
        case CLEAN_PLAYER_PROFILE_POSTS_REQUEST:
            return { ...state, loading: true, error: null }

        case POST_CREATE_FAILURE:
        case POST_DELETE_FAILURE:
        case USER_LIKE_POST_FAILURE:
        case REPOST_FAILURE:
        case GET_POST_RESPONSES_FAILURE:
        case CLEAN_PLAYER_PROFILE_POSTS_FAILURE:
            return { ...state, loading: false, error: action.payload }

        case POST_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                posts: [action.payload, ...state.posts],
                feedReply: [...state.feedReply, action.payload]
            };

        // Tal vez no haga falta el ...state.feed en feed: , ya que añade los posts del feed que ya habían. Probar y vigilar
        case GET_FEED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                feed: action.payload
            };
        case GET_PLAYER_PROFILE_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                profilePosts: [...state.profilePosts, ...action.payload.posts.content]
            };
        case CLEAN_PLAYER_PROFILE_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                profilePosts: []
            }
        case POST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                posts: state.posts.filter((post) => post.id !== action.payload)
            };
        case USER_LIKE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                feedLike: [...state.feedLike, action.payload]
            };
        case REPOST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                feedRepost: [...state.feedRepost, action.payload]
            };
        case GET_POST_RESPONSES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                postDetails: action.payload.repliedPost,
                postReplies: action.payload.replies.content
            }
        case REPLY_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}