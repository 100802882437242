export function formatValueForPieChart(value) {
    if (typeof value !== 'string') {
        return value; // Si no es un string, devolvemos el valor tal cual
    }

    // Separar las palabras en camelCase y luego capitalizar la primera letra
    let formattedValue = value.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalizar la primera letra y dejar el resto como estaba
    formattedValue = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);

    formattedValue = formattedValue.replace(' Percentage', '');

    return formattedValue;
}
