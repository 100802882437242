export const RELOAD_PAGE_REQUEST="RELOAD_PAGE_REQUEST"
export const RELOAD_PAGE_SUCCESS="RELOAD_PAGE_SUCCESS"
export const RELOAD_PAGE_FAILURE="RELOAD_PAGE_FAILURE"

export const LOGIN_USER_REQUEST="LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS="LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAILURE="LOGIN_USER_FAILURE"

export const REGISTER_USER_REQUEST="REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS="REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAILURE="REGISTER_USER_FAILURE"

export const GET_USER_PROFILE_REQUEST="GET_USER_PROFILE_REQUEST"
export const GET_USER_PROFILE_SUCCESS="GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAILURE="GET_USER_PROFILE_FAILURE"

export const FIND_USER_BY_ID_REQUEST="FIND_USER_BY_ID_REQUEST"
export const FIND_USER_BY_ID_SUCCESS="FIND_USER_BY_ID_SUCCESS"
export const FIND_USER_BY_ID_FAILURE="FIND_USER_BY_ID_FAILURE"

export const UPDATE_USER_PROFILE_REQUEST="UPDATE_USER_PROFILE_REQUEST"
export const UPDATE_USER_PROFILE_SUCCESS="UPDATE_USER_PROFILE_SUCCESS"
export const UPDATE_USER_PROFILE_FAILURE="UPDATE_USER_PROFILE_FAILURE"

export const FOLLOW_USER_REQUEST="FOLLOW_USER_REQUEST"
export const FOLLOW_USER_SUCCESS="FOLLOW_USER_SUCCESS"
export const FOLLOW_USER_FAILURE="FOLLOW_USER_FAILURE"

export const JWT_REFRESH_PROFILE_UPDATE_REQUEST="JWT_REFRESH_PROFILE_UPDATE_REQUEST"
export const JWT_REFRESH_PROFILE_UPDATE_SUCCESS="JWT_REFRESH_PROFILE_UPDATE_REQUEST"
export const JWT_REFRESH_PROFILE_UPDATE_FAILURE="JWT_REFRESH_PROFILE_UPDATE_REQUEST"

export const LOGOUT="LOGOUT"