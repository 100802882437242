import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { registerUser } from '../../Store/Auth/Action';
import { useNavigate } from 'react-router-dom';

import styles from './signupform.module.css'

const validationSchema = Yup.object().shape({
  mail: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum eight characters'),
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Minimum three characters'),
  username: Yup.string()
    .required('Username is required')
    .min(3, 'Minimum three characters'),
  sex: Yup.string()
    .required('Sex is required'),
  race: Yup.string()
    .required('Race is required'),
  culture: Yup.string()
    .required('Culture is required'),
  belief: Yup.string()
    .required('Belief is required')
});

const SignupForm = ({ loading }) => {

  // Utilizamos el useNavigate para que, una vez se envíe el formulario, se cambie a la url de /verify. Navigate lo pasamos al Action de registerUser.
  const navigate = useNavigate();

  const getGeolocationInfo = async () => {
    try {

      const geolocationData = await fetch("https://ipinfo.io/json?token=fde0b4c13cf86e").then(
        (response) => response.json());

      console.log(geolocationData);

      return {
        country: geolocationData.country,
        city: geolocationData.city
      }

    } catch (error) {
      console.error('Error fetching language:', error)
    }
  }


  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mail: '',
      password: '',
      name: '',
      username: '',
      sex: '',
      race: '',
      culture: '',
      belief: '',
    },
    validationSchema,
    onSubmit: async (values) => {

      try {
        loading(true);

        const geolocationInfo = await getGeolocationInfo();

        const valuesWithGeolocation = {
          ...values,
          city: geolocationInfo.city,
          country: geolocationInfo.country
        }

        const response = await dispatch(registerUser(valuesWithGeolocation))

        if (response) {
          navigate('/verify');
        } else {
          console.error("Registration failed:", response.message);
        }


        loading(false);

      } catch (error) {

        console.log(error)
        loading(false);

      } finally {
        loading(false);
      }
    },
  });

  const [isMailFocused, setIsMailFocused] = useState(false);

  const handleMailFocus = () => {
    setIsMailFocused(true);
  };

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const [isNameFocused, setIsNameFocused] = useState(false);

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const [isUsernameFocused, setIsUsernameFocused] = useState(false);

  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
  };

  const [isSexFocused, setIsSexFocused] = useState(false);

  const handleSexFocus = () => {
    setIsSexFocused(true);
  };

  const [isRaceFocused, setIsRaceFocused] = useState(false);

  const handleRaceFocus = () => {
    setIsRaceFocused(true);
  };

  const [isCultureFocused, setIsCultureFocused] = useState(false);

  const handleCultureFocus = () => {
    setIsCultureFocused(true);
  };

  const [isBeliefFocused, setIsBeliefFocused] = useState(false);

  const handleBeliefFocus = () => {
    setIsBeliefFocused(true);
  };

  return (

    <div className={styles.formWrapper}>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>

        <div className={styles.inputGroup}>

          <TextField
            className={styles.textField}
            label="Email"
            name="mail"
            variant="outlined"

            value={formik.values.mail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleMailFocus}
            error={formik.touched.mail && Boolean(formik.errors.mail)}
            helperText={formik.touched.mail && formik.errors.mail}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isMailFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />


          <TextField
            className={styles.textField}
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handlePasswordFocus}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isPasswordFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </div>
        <div className={styles.inputGroup}>
          <TextField
            className={styles.textField}
            label="Name"
            name="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleNameFocus}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isNameFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

          <TextField
            className={styles.textField}
            label="Username"
            name="username"
            variant="outlined"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleUsernameFocus}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isUsernameFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          />

        </div>
        <div className={styles.inputGroup}>
          <TextField
            className={styles.textField}
            id='sex'
            label="Sex"
            select
            name="sex"
            variant="outlined"
            value={formik.values.sex}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleSexFocus}
            error={formik.touched.sex && Boolean(formik.errors.sex)}
            helperText={formik.touched.sex && formik.errors.sex}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isSexFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='MALE'>Male</MenuItem>
            <MenuItem value='FEMALE'>Female</MenuItem>

          </TextField>

          <TextField
            className={styles.textField}
            id='race'
            label="Race"
            select
            name="race"
            variant="outlined"
            value={formik.values.race}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleRaceFocus}
            error={formik.touched.race && Boolean(formik.errors.race)}
            helperText={formik.touched.race && formik.errors.race}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isRaceFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='MEDITERRANEAN'>Mediterranean</MenuItem>
            <MenuItem value='NORDIC_ALPINE'>Nordic-Alpine</MenuItem>
            <MenuItem value='ARMENOID'>Armenoid</MenuItem>
            <MenuItem value='SEMITIC'>Semitic</MenuItem>
            <MenuItem value='HAMITE'>Hamite</MenuItem>
            <MenuItem value='IRANID'>Iranid</MenuItem>
            <MenuItem value='CONGOID'>Congoid</MenuItem>
            <MenuItem value='CAPOID'>Capoid</MenuItem>
            <MenuItem value='AUSTRALO_MELANESIAN'>Australo Melanesian</MenuItem>
            <MenuItem value='DRAVIDIAN'>Dravinian</MenuItem>
            <MenuItem value='MONGOLOID'>Mongoloid</MenuItem>
            <MenuItem value='POLYNESIAN'>Polynesian</MenuItem>
            <MenuItem value='AMERINDIAN'>Amerindian</MenuItem>
            <MenuItem value='ESKIMO'>Eskimo</MenuItem>
            <MenuItem value='MULATTO'>Mulatto</MenuItem>
            <MenuItem value='MESTIZO'>Mestizo</MenuItem>
            <MenuItem value='ZAMBO'>Zambo</MenuItem>


          </TextField>

        </div>

        <div className={styles.inputGroup}>

          <TextField
            className={styles.textField}
            id='culture'
            label="Culture"
            select
            name="culture"
            variant="outlined"
            value={formik.values.culture}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleCultureFocus}
            error={formik.touched.culture && Boolean(formik.errors.culture)}
            helperText={formik.touched.culture && formik.errors.culture}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isCultureFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='NORTH_GERMAN'>North German</MenuItem>
            <MenuItem value='SOUTH_GERMAN'>South German</MenuItem>
            <MenuItem value='ASHKENAZI'>Ashkenazi</MenuItem>
            <MenuItem value='SEPHARDIM'>Sephardim</MenuItem>
            <MenuItem value='DUTCH'>Dutch</MenuItem>
            <MenuItem value='FLEMISH'>Flemish</MenuItem>
            <MenuItem value='WALLONIAN'>Wallonian</MenuItem>
            <MenuItem value='BOER'>Boer</MenuItem>
            <MenuItem value='ALEMANNIC'>Alemannic</MenuItem>
            <MenuItem value='FRANCO_PROVENCAL'>Franco Provencal</MenuItem>
            <MenuItem value='SWEDISH'>Swedish</MenuItem>
            <MenuItem value='DANISH'>Danish</MenuItem>
            <MenuItem value='NORWEGIAN'>Norwegian</MenuItem>
            <MenuItem value='ICELANDIC'>Icelandic</MenuItem>
            <MenuItem value='FINNISH'>Finnish</MenuItem>
            <MenuItem value='SAMI'>Sami</MenuItem>
            <MenuItem value='ENGLISH'>English</MenuItem>
            <MenuItem value='SCOTTISH'>Scottish</MenuItem>
            <MenuItem value='WELSH'>Welsh</MenuItem>
            <MenuItem value='AUSTRALIAN'>Australian</MenuItem>
            <MenuItem value='IRISH'>Irish</MenuItem>
            <MenuItem value='BASQUE'>Basque</MenuItem>
            <MenuItem value='SPANISH'>Spanish</MenuItem>
            <MenuItem value='CATALAN'>Catalan</MenuItem>
            <MenuItem value='GALICIAN'>Galician</MenuItem>
            <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
            <MenuItem value='FRENCH'>French</MenuItem>
            <MenuItem value='OCCITAN'>Occitan</MenuItem>

            <MenuItem value='BRETON'>Breton</MenuItem>
            <MenuItem value='CORSICAN'>Corsican</MenuItem>
            <MenuItem value='SOUTH_ITALIAN'>South Italian</MenuItem>
            <MenuItem value='NORTH_ITALIAN'>North Italian</MenuItem>
            <MenuItem value='MALTESE'>Maltese</MenuItem>
            <MenuItem value='ALBANIAN'>Albanian</MenuItem>


            <MenuItem value='BOSNIAK'>Bosniak</MenuItem>
            <MenuItem value='BULGARIAN'>Bulgarian</MenuItem>
            <MenuItem value='CROATIAN'>Croatian</MenuItem>
            <MenuItem value='SERBIAN'>Serbian</MenuItem>
            <MenuItem value='SLOVENE'>Slovene</MenuItem>
            <MenuItem value='ROMANIAN'>Romanian</MenuItem>
            <MenuItem value='HUNGARIAN'>Hungarian</MenuItem>
            <MenuItem value='POLISH'>Polish</MenuItem>
            <MenuItem value='CZECH'>Czech</MenuItem>
            <MenuItem value='SLOVAK'>Slovak</MenuItem>
            <MenuItem value='SORB'>Sorb</MenuItem>
            <MenuItem value='LITHUANIAN'>Lithuanian</MenuItem>
            <MenuItem value='LATVIAN'>Latvian</MenuItem>
            <MenuItem value='ESTONIAN'>Estonian</MenuItem>
            <MenuItem value='UGRIAN'>Ugrian</MenuItem>
            <MenuItem value='RUSSIAN'>Russian</MenuItem>
            <MenuItem value='BYELORUSSIAN'>Byelorussian</MenuItem>
            <MenuItem value='UKRAINIAN'>Ukrainian</MenuItem>
            <MenuItem value='GREEK'>Greek</MenuItem>
            <MenuItem value='GEORGIAN'>Georgian</MenuItem>
            <MenuItem value='ARMENIAN'>Armenian</MenuItem>
            <MenuItem value='CIRCASSIAN'>Circassian</MenuItem>
            <MenuItem value='NORTH_CAUCASIAN'>North Caucasian</MenuItem>
            <MenuItem value='TATAR'>Tatar</MenuItem>
            <MenuItem value='TURKISH'>Turkish</MenuItem>
            <MenuItem value='AZERBAIJANI'>Azerbaijani</MenuItem>
            <MenuItem value='MAGHREBI'>Maghrebi</MenuItem>
            <MenuItem value='MISRI'>Misri</MenuItem>
            <MenuItem value='MASHRIQI'>Mashriqi</MenuItem>
            <MenuItem value='BEDOUIN'>Bedouin</MenuItem>

            <MenuItem value='AMAZIGH'>Amazigh</MenuItem>
            <MenuItem value='YEMENITE'>Yemenite</MenuItem>
            <MenuItem value='BIDAN'>Bidan</MenuItem>
            <MenuItem value='HARATIN'>Haratin</MenuItem>
            <MenuItem value='PERSIAN'>Persian</MenuItem>
            <MenuItem value='KURDISH'>Kurdish</MenuItem>
            <MenuItem value='ASSYRIAN'>Assyrian</MenuItem>
            <MenuItem value='UZBEK'>Uzbek</MenuItem>
            <MenuItem value='KAZAKH'>Kazakh</MenuItem>
            <MenuItem value='KYRGYZ'>Kyrgyz</MenuItem>
            <MenuItem value='TAJIK'>Tajik</MenuItem>
            <MenuItem value='UIGHUR'>Uighur</MenuItem>
            <MenuItem value='PASHTUN'>Pashtun</MenuItem>
            <MenuItem value='BALUCHI'>Baluchi</MenuItem>
            <MenuItem value='HAZARA'>Hazara</MenuItem>
            <MenuItem value='TURKMEN'>Turkmen</MenuItem>
            <MenuItem value='KALMYK'>Kalmyk</MenuItem>
            <MenuItem value='MONGOL'>Mongol</MenuItem>
            <MenuItem value='TUVAN'>Tuvan</MenuItem>
            <MenuItem value='SIBERIAN'>Siberian</MenuItem>
            <MenuItem value='YAKUT'>Yakut</MenuItem>
            <MenuItem value='AINU'>Ainu</MenuItem>
            <MenuItem value='ASSAMESE'>Assamese</MenuItem>
            <MenuItem value='BENGALI'>Bengali</MenuItem>
            <MenuItem value='BIHARI'>Bihari</MenuItem>
            <MenuItem value='MANIPURI'>Manipuri</MenuItem>
            <MenuItem value='NEPALI'>Nepali</MenuItem>
            <MenuItem value='ORIYA'>Oriya</MenuItem>
            <MenuItem value='SINHALA'>Sinhala</MenuItem>
            <MenuItem value='AVADHI'>Avadhi</MenuItem>
            <MenuItem value='KANAUJI'>Kanauji</MenuItem>
            <MenuItem value='PANJABI'>Panjabi</MenuItem>
            <MenuItem value='KASHMIRI'>Kashmiri</MenuItem>
            <MenuItem value='GUJARATI'>Gujarati</MenuItem>
            <MenuItem value='MARATHI'>Marathi</MenuItem>
            <MenuItem value='SINDI'>Sindi</MenuItem>
            <MenuItem value='RAJPUT'>Rajput</MenuItem>
            <MenuItem value='KANNADA'>Kannada</MenuItem>
            <MenuItem value='MALAYALAM'>Malayalam</MenuItem>
            <MenuItem value='TAMIL'>Tamil</MenuItem>
            <MenuItem value='TELEGU'>Telegu</MenuItem>
            <MenuItem value='KHMER'>Khmer</MenuItem>

            <MenuItem value='MALAY'>Malay</MenuItem>
            <MenuItem value='BORNEAN'>Bornean</MenuItem>
            <MenuItem value='SUMATRAN'>Sumatran</MenuItem>
            <MenuItem value='BALINESE'>Balinese</MenuItem>
            <MenuItem value='BATAK'>Batak</MenuItem>
            <MenuItem value='DAYAK'>Dayak</MenuItem>
            <MenuItem value='FILIPINO'>Filipino</MenuItem>
            <MenuItem value='MORO'>Moro</MenuItem>
            <MenuItem value='JAVAN'>Javan</MenuItem>
            <MenuItem value='MOLUCCAN'>Moluccan</MenuItem>
            <MenuItem value='THAI'>Thai</MenuItem>
            <MenuItem value='MON'>Mon</MenuItem>
            <MenuItem value='KHMU'>Khmu</MenuItem>
            <MenuItem value='LAO'>Lao</MenuItem>
            <MenuItem value='SHAN'>Shan</MenuItem>
            <MenuItem value='BURMESE'>Burmese</MenuItem>
            <MenuItem value='KACHIN'>Kachin</MenuItem>
            <MenuItem value='KAREN'>Karen</MenuItem>
            <MenuItem value='VIETNAMESE'>Vietnamese</MenuItem>
            <MenuItem value='MANCHU'>Manchu</MenuItem>
            <MenuItem value='HAN'>Han</MenuItem>
            <MenuItem value='HAKKA'>Hakka</MenuItem>
            <MenuItem value='MIN'>Min</MenuItem>
            <MenuItem value='YUE'>Yue</MenuItem>
            <MenuItem value='JAPANESE'>Japanese</MenuItem>
            <MenuItem value='KOREAN'>Korean</MenuItem>
            <MenuItem value='MIAO'>Miao</MenuItem>
            <MenuItem value='ZHUANG'>Zhuang</MenuItem>
            <MenuItem value='YI'>Yi</MenuItem>
            <MenuItem value='TIBETAN'>Tibetan</MenuItem>
            <MenuItem value='POLYNESIAN'>Polynesian</MenuItem>
            <MenuItem value='HAWAIIAN'>Hawaiian</MenuItem>
            <MenuItem value='MELANESIAN'>Melanesian</MenuItem>
            <MenuItem value='MICRONESIAN'>Micronesian</MenuItem>
            <MenuItem value='MAORI'>Maori</MenuItem>
            <MenuItem value='YUANZHUMIN'>Yuanzhumin</MenuItem>
            <MenuItem value='ABORIGINAL'>Aboriginal</MenuItem>
            <MenuItem value='MAYA'>Maya</MenuItem>
            <MenuItem value='MISKITO'>Miskito</MenuItem>
            <MenuItem value='NAHUA'>Nahua</MenuItem>
            <MenuItem value='TARASCAN'>Tarascan</MenuItem>
            <MenuItem value='ZAPOTEC'>Zapotec</MenuItem>
            <MenuItem value='MIXTEC'>Mixtec</MenuItem>
            <MenuItem value='AIMARA'>Aimara</MenuItem>
            <MenuItem value='AMAZONIAN'>Amazonian</MenuItem>
            <MenuItem value='GUAJIRO'>Guajiro</MenuItem>
            <MenuItem value='GUARANI'>Guarani</MenuItem>
            <MenuItem value='PATAGONIAN'>Patagonian</MenuItem>
            <MenuItem value='TUPINAMBA'>Tupinamba</MenuItem>
            <MenuItem value='QUECHUA'>Quechua</MenuItem>
            <MenuItem value='MUISCA'>Muisca</MenuItem>
            <MenuItem value='CARIBAN'>Cariban</MenuItem>
            <MenuItem value='METIS'>Metis</MenuItem>
            <MenuItem value='DAKOTA'>Dakota</MenuItem>
            <MenuItem value='CHEROKEE'>Cherokee</MenuItem>
            <MenuItem value='MUSKOGEAN'>Muskogean</MenuItem>
            <MenuItem value='PUEBLO'>Pueblo</MenuItem>
            <MenuItem value='INUIT'>Inuit</MenuItem>
            <MenuItem value='CREE'>Cree</MenuItem>
            <MenuItem value='NAVAJO'>Navajo</MenuItem>
            <MenuItem value='ATHABASKAN'>Athabaskan</MenuItem>
            <MenuItem value='SALISH'>Salish</MenuItem>
            <MenuItem value='NEZ_PERCE'>Nez Perce</MenuItem>
            <MenuItem value='SIOUAN'>Siouan</MenuItem>
            <MenuItem value='COMANCHE'>Comanche</MenuItem>
            <MenuItem value='ALGONQUIAN'>Algonquian</MenuItem>
            <MenuItem value='IROQUOIAN'>Iroquoian</MenuItem>
            <MenuItem value='CADDOAN'>Caddoan</MenuItem>

            <MenuItem value='PAIUTE'>Paiute</MenuItem>
            <MenuItem value='HOKAN'>Hokan</MenuItem>
            <MenuItem value='APACHE'>Apache</MenuItem>
            <MenuItem value='O_ODHAM'>O Odham</MenuItem>
            <MenuItem value='YANKEE'>Yankee</MenuItem>
            <MenuItem value='DIXIE'>Dixie</MenuItem>
            <MenuItem value='ANGLO_CANADIAN'>Anglo Canadian</MenuItem>
            <MenuItem value='FRANCO_CANADIAN'>Franco Canadian</MenuItem>
            <MenuItem value='CAJUN'>Cajun</MenuItem>
            <MenuItem value='MEXICAN'>Mexican</MenuItem>
            <MenuItem value='CENTRAL_AMERICAN'>Central American</MenuItem>
            <MenuItem value='CARIBENYO'>Caribenyo</MenuItem>
            <MenuItem value='NORTH_ANDEAN'>North Andean</MenuItem>
            <MenuItem value='SOUTH_ANDEAN'>South Andean</MenuItem>
            <MenuItem value='PLATINEAN'>Platinean</MenuItem>
            <MenuItem value='BRAZILIAN'>Brazilian</MenuItem>
            <MenuItem value='AFRO_AMERICAN'>Afro American</MenuItem>
            <MenuItem value='AFRO_CARIBBEAN'>Afro Caribbean</MenuItem>
            <MenuItem value='AFRO_CARIBENYO'>Afro Caribenyo</MenuItem>
            <MenuItem value='AFRO_BRAZILIAN'>Afro Brazilian</MenuItem>
            <MenuItem value='AFRO_ANTILLEAN'>Afro Antillean</MenuItem>
            <MenuItem value='AKAN'>Akan</MenuItem>
            <MenuItem value='BAMBARA'>Bambara</MenuItem>
            <MenuItem value='BASSA'>Bassa</MenuItem>
            <MenuItem value='DYULA'>Dyula</MenuItem>
            <MenuItem value='EDO'>Edo</MenuItem>
            <MenuItem value='EWE'>Ewe</MenuItem>
            <MenuItem value='FON'>Fon</MenuItem>
            <MenuItem value='FULBE'>Fulbe</MenuItem>
            <MenuItem value='HAUSA'>Hausa</MenuItem>
            <MenuItem value='IBIBIO'>Ibibio</MenuItem>
            <MenuItem value='IBO'>Ibo</MenuItem>
            <MenuItem value='KISSI'>Kissi</MenuItem>
            <MenuItem value='KRU'>Kru</MenuItem>
            <MenuItem value='MANDE'>Mande</MenuItem>
            <MenuItem value='MOSSI'>Mossi</MenuItem>
            <MenuItem value='SENUFO'>Senufo</MenuItem>
            <MenuItem value='SONGHAI'>Songhai</MenuItem>
            <MenuItem value='TIV'>Tiv</MenuItem>
            <MenuItem value='TUAREG'>Tuareg</MenuItem>
            <MenuItem value='WOLOF'>Wolof</MenuItem>
            <MenuItem value='YORUBA'>Yoruba</MenuItem>
            <MenuItem value='BAKONGO'>Bakongo</MenuItem>
            <MenuItem value='BAGUIRMI'>Baguirmi</MenuItem>
            <MenuItem value='FANG'>Fang</MenuItem>
            <MenuItem value='KANURI'>Kanuri</MenuItem>
            <MenuItem value='LUBA'>Luba</MenuItem>
            <MenuItem value='LUNDA'>Lunda</MenuItem>
            <MenuItem value='MONGO'>Mongo</MenuItem>
            <MenuItem value='SARA'>Sara</MenuItem>
            <MenuItem value='TEDA'>Teda</MenuItem>
            <MenuItem value='EQUATORIAL_BANTU'>Equatorial Bantu</MenuItem>
            <MenuItem value='FLUVIAL_BANTU'>Fluvial Bantu</MenuItem>
            <MenuItem value='NILOTIC'>Nilotic</MenuItem>
            <MenuItem value='AMHARA'>Amhara</MenuItem>
            <MenuItem value='AFAR'>Afar</MenuItem>
            <MenuItem value='AZANDE'>Azande</MenuItem>
            <MenuItem value='BAGANDA'>Baganda</MenuItem>
            <MenuItem value='BEJA'>Beja</MenuItem>
            <MenuItem value='DINKA'>Dinka</MenuItem>
            <MenuItem value='FUR'>Fur</MenuItem>
            <MenuItem value='KIKUYU'>Kikuyu</MenuItem>
            <MenuItem value='LUO'>Luo</MenuItem>
            <MenuItem value='MAASAI'>Maasai</MenuItem>
            <MenuItem value='NUER'>Nuer</MenuItem>
            <MenuItem value='NUBA'>Nuba</MenuItem>
            <MenuItem value='OROMO'>Oromo</MenuItem>
            <MenuItem value='RUANDA'>Ruanda</MenuItem>

            <MenuItem value='RUNDI'>Rundi</MenuItem>
            <MenuItem value='SIDAMA'>Sidama</MenuItem>
            <MenuItem value='SOMALI'>Somali</MenuItem>
            <MenuItem value='SUDANESE'>Sudanese</MenuItem>
            <MenuItem value='SUKUMA'>Sukuma</MenuItem>
            <MenuItem value='SWAHILI'>Swahili</MenuItem>
            <MenuItem value='TIGRAY'>Tigray</MenuItem>
            <MenuItem value='UNYAMWEZI'>Unyamwezi</MenuItem>
            <MenuItem value='LACUSTRINE_BANTU'>Lacustrine Bantu</MenuItem>
            <MenuItem value='MALAGASY'>Malagasy</MenuItem>
            <MenuItem value='CHEWA'>Chewa</MenuItem>
            <MenuItem value='HERERO'>Herero</MenuItem>
            <MenuItem value='KHOISAN'>Khoisan</MenuItem>
            <MenuItem value='LOMWE'>Lomwe</MenuItem>
            <MenuItem value='MAKUA'>Makua</MenuItem>
            <MenuItem value='NGUNI'>Nguni</MenuItem>
            <MenuItem value='OVIMBUNDU'>Ovimbundu</MenuItem>
            <MenuItem value='SENA'>Sena</MenuItem>
            <MenuItem value='SHONA'>Shona</MenuItem>
            <MenuItem value='SOTHO'>Sotho</MenuItem>
            <MenuItem value='TONGA'>Tonga</MenuItem>
            <MenuItem value='TSWANA'>Tswana</MenuItem>
            <MenuItem value='WHOSA'>Whosa</MenuItem>
            <MenuItem value='YAO'>Yao</MenuItem>
            <MenuItem value='ZULU'>Zulu</MenuItem>
            <MenuItem value='KAVANGO_BANTU'>Kavango Bantu</MenuItem>


          </TextField>

          <TextField
            className={styles.textField}
            id='belief'
            label="Belief"
            select
            name="belief"
            variant="outlined"
            value={formik.values.belief}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={handleBeliefFocus}
            error={formik.touched.belief && Boolean(formik.errors.belief)}
            helperText={formik.touched.belief && formik.errors.belief}
            InputProps={{
              style: {
                backgroundColor: '#313338', // Reemplaza 'tu_color_de_fondo' con el color deseado
                border: isBeliefFocused ? 'none' : '1px solid #71767b', // Reemplaza 'tu_color_de_borde' con el color deseado
                color: 'white'
              },
            }}
            InputLabelProps={{
              style: {
                color: '#71767b', // Reemplaza 'white' con el color deseado
              },
            }}
          >

            <MenuItem value='ATHEISM'>Atheism</MenuItem>
            <MenuItem value='AGNOSTICISM'>Agnosticism</MenuItem>
            <MenuItem value='ORTHODOX_JUDAISM'>Orthodox Judaism</MenuItem>
            <MenuItem value='CONSERVATIVE_JUDAISM'>Conservative Judaism</MenuItem>
            <MenuItem value='REFORM_JUDAISM'>Reform Judaism</MenuItem>
            <MenuItem value='CATHOLICISM'>Catholicism</MenuItem>
            <MenuItem value='ORTHODOX_CHRISTIANITY'>Orthodox Christianity</MenuItem>
            <MenuItem value='COPTIC_ORTHODOX'>Coptic Orthodox</MenuItem>
            <MenuItem value='PROTESTANTISM'>Protestantism</MenuItem>
            <MenuItem value='SUNNISM'>Sunnism</MenuItem>
            <MenuItem value='SHIISM'>Shiism</MenuItem>
            <MenuItem value='KHARIJISM'>Kharijism</MenuItem>
            <MenuItem value='VAISHNAVISM'>Vaishnavism</MenuItem>
            <MenuItem value='SHAIVISM'>Shaivism</MenuItem>
            <MenuItem value='SHAKTISM'>Shaktism</MenuItem>
            <MenuItem value='SMARTISM'>Smartism</MenuItem>
            <MenuItem value='CONFUCIANISM'>Confucianism</MenuItem>
            <MenuItem value='TAOISM'>Taoism</MenuItem>
            <MenuItem value='SHENISM'>Shenism</MenuItem>
            <MenuItem value='MAHAYANA'>Mahayana</MenuItem>
            <MenuItem value='VAJRAYANA'>Vajrayana</MenuItem>
            <MenuItem value='THERAVADA'>Therevada</MenuItem>


          </TextField>

        </div>

        <div className={styles.importantNotice}>
          <p style={{ color: 'red' }}><small><b>IMPORTANT: </b>Sex, Race, Culture and Belief and the username cannot be changed later</small></p>
        </div>

        <button
          className={styles.submitButton}
          type="submit"
        >
          <strong>Sign up</strong>
        </button>


      </form>
    </div>
  );
};

export default SignupForm;
