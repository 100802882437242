import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

// Empleamos este contexto para saber cuando se ha hecho scroll hacia abajo y cuando se ha hecho hacia arriba
// pudiendo registrar divs para escuchar estos eventos de scroll, y alterando el isVisible para que se muestre
// o se deje de mostrar el navigationMenu inferior para móviles

const ScrollContext = createContext();

export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  // Es un estado que determina si el elemento debe ser visible o no
  const [isVisible, setIsVisible] = useState(true);
  // Es una referencia que guarda la última posición de desplazamiento para cada elemento scrollable
  const lastScrollTopRef = useRef({});
  // Es una referencia que guarda los elementos que son scrollables
  const scrollableElementsRef = useRef([]);

  const registerScrollableElement = (element) => {
    if (element && !scrollableElementsRef.current.includes(element)) {
      scrollableElementsRef.current.push(element);
      element.addEventListener('scroll', handleScroll, { passive: true });
    }
  };

  const unregisterScrollableElement = (element) => {
    // Eliminamos primero el elemento que está en el array de la referencia
    scrollableElementsRef.current = scrollableElementsRef.current.filter(el => el !== element);
    if (element) {
      element.removeEventListener('scroll', handleScroll);
    }
  };

  const handleScroll = (event) => {
    // event.target es el elemento que disparó el evento de desplazamiento.
    const element = event.target;
    // Devuelve la cantidad de píxeles que el contenido del elemento ha sido desplazado hacia arriba. Es útil para determinar cuánto se ha desplazado el usuario en el elemento
    const currentScrollTop = element.scrollTop;
    // La referencia lastScrollTopRef mantiene un registro de la última posición de desplazamiento para cada elemento. Aquí, intentamos obtener la última posición de desplazamiento registrada para el elemento actual
    const lastScrollTop = lastScrollTopRef.current[element] || 0;

    if (currentScrollTop > lastScrollTop) {
      // Scrolling down
      setIsVisible(false);
    } else {
      // Scrolling up
      setIsVisible(true);
    }

    // Actualiza el registro de la última posición de desplazamiento para el elemento actual
    lastScrollTopRef.current[element] = currentScrollTop <= 0 ? 0 : currentScrollTop;
  };

  useEffect(() => {
    return () => {
      scrollableElementsRef.current.forEach(element => {
        element.removeEventListener('scroll', handleScroll);
      });
    };
  }, []);

  return (
    <ScrollContext.Provider value={{ isVisible, registerScrollableElement, unregisterScrollableElement }}>
      {children}
    </ScrollContext.Provider>
  );
};