import axios from "axios"
import { API_BASE_URL, api, updateAuthorizationHeader } from "../../Config/api"
import { GET_MAP_FAILURE, GET_MAP_SUCCESS } from "./ActionType"

export const getWorldviewMap = () => async (dispatch) => {
    try {
        const { data } = await api.get(`/api/v1/worldview`);

        dispatch({ type: GET_MAP_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_MAP_FAILURE, payload: error.message })
    }
}